/*------------------------------------------------------------------

 - Header

-------------------------------------------------------------------*/

.navbar-toggler {
  border: none !important;
  outline: none !important;
}

.e-toggle {
  width: 16px;
  top: 30px;
  z-index: 9999;
}

.hidden {
  display: none;
}

.e-close {
  margin-top: 30px;
}

.e-overlay {
  opacity: 0.8;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: white;
  width: 100%;
  height: 100%;
}

.navbar {
  padding: 0 !important;
}

.e-navbar-collapse {
  position: fixed;
  top: 80px;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 9;
  padding: 10px 30px;
}

.e-header-link {
  color: #444444;
  font-size: 14px;
  line-height: 18px;
  font-family: HK Grotesk;
  font-weight: 600;
  position: relative;
  text-decoration: none;
  vertical-align: middle;
}

.e-header-link-active {
  color: #0461D1;
}

.e-header-logo {
  margin-top: -10px;

}

.e-head-logo-link {
  margin-left: -15px;
}

.e-header-img-link {
  /*margin-left: -20px !important;*/
}

.e-fixed {
  position: fixed !important;
  z-index: 999;
  width: 90%;
}

.e-header-btn {
  border: 1px solid #0461D1;
  border-radius: 4px;
  color: #0461D1;
  font-size: 14px;
  line-height: 18px;
  font-feature-settings: 'ss01' on, 'ss03' on;
  background: #FFFFFF;
  padding: 10px 20px;
  font-weight: 600;
  margin-top: -10px;
  transition: ease-in 0.3s;
}

.e-header-btn:hover {
  background: #0461D1;
  color: #FFFFFF;
}

.e-header-blue-btn {
  background: #0461D1;
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 14px;
  line-height: 18px;
  font-feature-settings: 'ss01' on, 'ss03' on;
  padding: 11px 20px;
  font-weight: 600;
  margin-top: -10px;
  transition: ease-in 0.3s;
}

.e-header-blue-btn:hover {
  background: #0e2d5f;
}

.e-header-link:before {
  content: "";
  position: absolute;
  width: 0;
  height: 4px;
  top: 20px;
  left: 30px;
  background-color: #0461D1;
  visibility: hidden;
  transition: all 0.3s ease-in-out;

  color: #0461D1;
}

.e-header-link-active:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 4px;
  top: 20px;
  left: 50%;
  border-radius: 50%;
  visibility: visible;
  color: #0461D1;
  background-color: #0461D1;
}

.e-header-link:hover:before {
  color: #0461D1;
  visibility: visible;
  width: 5px;
  height: 4px;
  top: 20px;
  left: 50%;
  border-radius: 50%;
}

.e-header-link:hover {
  text-decoration: none;
}


/*user header*/
.e-user-placeholder,
.e-user-span,
.e-partner-placeholder,
.e-partner-span {
  color: #FFFFFF;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  font-weight: 800;
}

.e-user-placeholder,
.e-user-span {
  background: #0461D1;
  padding-top: 8px;
  width: 35px;
  height: 35px;
  display: inline-block;
  text-align: center;
}

.e-partner-placeholder,
.e-partner-span {
  padding: 6px;
  background: #13D726;
  border: 4px solid #0461D1;
}

.e-user-span {
  position: relative;
  top: -5px;
}

.e-user-placeholder,
.e-partner-placeholder {
  margin-left: 100px;
}

.e-usr-select {
  width: 300px;
}

.e-usr-dropdown {
  display: none;
}

.e-usr-menu-usrname {
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #1B1C1E;
  margin-left: 0;
  text-transform: capitalize;
}

.e-usr-menu-mail {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #1B1C1E;
}

.e-usr-menu-id {
  margin-block-end: 2px;
}

/*.css-2613qy-menu {
	overflow-x: hidden;
}*/
/*.css-peowp8-control {
	overflow-x: hidden;
}*/
.e-usr-nav-link {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.01em;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  color: #1B1C1E
}

.css-11unzgr {
  max-height: 300px;
  overflow: hidden;
  padding-bottom: 4px;
  padding-top: 4px;
  position: relative;
  box-sizing: border-box;
}

.e-usr-nav-link:hover {
  text-decoration: none;
  color: #1B1C1E;
}

.e-usr-select .css-19bqh2r {
  fill: white;
}

.e-usr-select .css-g1d714-ValueContainer {
  padding: 12px 120px 12px;
}

.e-usr-select .css-1l6guga-control {
  border: none;
  overflow-x: hidden;
}

.e-usr-select .css-1l6guga-control:hover {
  border: none;
}

.e-usr-select .css-peowp8-control {
  border: none !important;
}

.e-usr-select .css-1wa3eu0-placeholder {
  margin-left: 108px;
}

.e-usr-select .css-tlfecz-indicatorContainer {
  background-image: url('./images/selectdownarrow.svg');
  background-repeat: no-repeat;
  background-position: 0% 50%;
}

/*.e-usr-select .css-peowp8-control{
	background-image: url('./images/selectdownarrow.svg');
	background-repeat: no-repeat;
	background-position: 95% 50%;

}*/
.e-usr-select .css-26l3qy-menu .css-1n7v3ny-option,
.e-usr-select .css-26l3qy-menu .css-9gakcf-option {
  background-color: white;
}

.e-usr-select .css-26l3qy-menu {
  background: #FFFFFF;
  box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.e-usr-select .css-4ljt47-MenuList {
  overflow-x: hidden !important;
}

.e-usr-select .css-6q0nyr-Svg {
  display: none;
}

.e-nav-dropdown .css-26l3qy-menu {
  padding: 15px !important;
  overflow: hidden !important;
}

.e-usr-menu-usrname {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.e-usr-select .css-4ljt47-MenuList {
  overflow: hidden;
}

/*------------------------------------------------------------------

 - Footer

-------------------------------------------------------------------*/
.e-fooet-sec {
  padding: 40px 80px;
  position: relative;
  z-index: 2;
}

.e-marque {
  color: #F0F0F0;
  opacity: 0.67;
  font-size: 12px;
  line-height: 16px;
  font-feature-settings: 'liga' off;
  font-weight: 400;
}

.e-footer-newsletter-sec {
  background: #EAEFF3;
}

.e-footer-newsletter-sec p {
  font-size: 12px;
  line-height: 24px;
}

.e-footer-newsletter-sec h6 {
  font-size: 14px;
  line-height: 18px;
  color: #444444;
  font-weight: 800;
}

.e-footer-blueline {
  color: #0461D1;
}

.e-footer-grnline {
  color: #13D726;
  font-weight: 800;
}

.e-ftricon-link {
  text-decoration: none;
  color: #444444;
  font-size: 16px;
}

.e-evolab-link {
  color: #444444;
  text-decoration: underline;
}

.e-footer-bold-paragraph {
  font-size: 14px !important;
  text-decoration: none;
  color: #464646;
  opacity: 0.67;
  font-weight: 200;
}

.e-footer-bold-paragraph:hover {
  text-decoration: none;
  color: #444444;

}

.e-footer-companyinfo {
  color: #464646;
}

.e-footer-companyinfo a {
  color: #464646;
}

.e-footer-link {
  transition: ease-in-out 0.5s;
}

.e-footer-link:hover {
  font-weight: 600;
  cursor: pointer;
}

.e-footer-email-input {
  background: #FFFFFF;
  border-radius: 4px 0px 0px 4px;
  padding: 8px 25px 8px 15px;
}

.e-footer-email-input::placeholder {
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  color: #999999;
  font-size: 14px;
}

.e-footer-subs-btn {
  background: #0461D1;
  border-radius: 0px 4px 4px 0px;
  padding: 12px 22px 12px 22px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
  transition: ease-in-out 0.5s;
}

.e-footer-subs-btn:hover {
  background: #0C3E90;
}

.e-footer-address-sec {
  background: #161616;
}

.e-footer-address-sec p {
  color: #F0F0F0;
  opacity: 0.67;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

.e-footer-declaimer-sec {
  background: #1D1D1D;
}

.e-footer-declaimer-sec p {
  font-size: 12px;
  line-height: 16px;
  font-feature-settings: 'liga' off;
  color: #F0F0F0;

}

.e-footer-icon {
  transition: ease-in-out 0.5s;
  cursor: pointer;
}

.e-footer-icon:hover {
  transform: translateY(-3px);
  text-decoration: none;
}

/*------------------------------------------------------------------

 - App store banner

-------------------------------------------------------------------*/
.e-playstore-banner {
  background-color: #FFFFFF;
  border-radius: 8px;
  position: relative;
  box-shadow: 0px 16px 32px rgba(221, 230, 237, 0.54);
  padding: 33px 40px 50px 40px;
}

.e-playstorebanner-img {
  position: absolute;
  height: 350px;
  z-index: 1;
  right: 0;
  bottom: 0;
}

.e-playstorebannerbg-red {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
}

.e-playstorebannerbg-bluelg {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 8px;
}

.e-playstorebannerbg-orng {
  position: absolute;
  right: 400px;
  top: 100px;
}

.e-playstorebannerbg-blue {
  position: absolute;
  bottom: 50px;
  right: 340px;
}

.e-playstorebannerbg-green {
  position: absolute;
  bottom: 0;
  right: 30px;
}

.e-playstorebanner-head {
  position: relative;
  color: #444444;
  font-size: 36px;
  z-index: 1;
  line-height: 48px;
}

.e-playstorebanner-para {
  color: #444444;
  font-size: 16px;
  line-height: 28px;
}

.e-playstorelink-btn {
  background-image: url("./images/PlayStoreLink.png");
  background-repeat: no-repeat;
  padding: 20px 68px;
  transition: ease-in-out 0.2s;
  margin-top: 20px;
}

.e-appstorelink-btn {
  background-image: url("./images/AppStoreLink.png");
  background-repeat: no-repeat;
  padding: 20px 68px;
  transition: ease-in-out 0.2s;
}

.e-playstorelink-btn:hover,
.e-appstorelink-btn:hover {
  transform: translateY(-3px);
  transition-delay: 0.1s;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.e-link-txt {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  font-feature-settings: 'ss01' on, 'ss03' on;
  cursor: pointer;
  color: #0461D1;
  display: inline-block;
}

.e-link-txt:hover {
  opacity: .7;
}

/*------------------------------------------------------------------

 - Get Started Banner

-------------------------------------------------------------------*/

.e-getstart-banner {
  background-image: url("../../assets/images/GetStartBanner/getstartbgimg.svg");
  background-repeat: no-repeat;
  background-color: #0461D1;
  border-radius: 8px;
  padding-bottom: 60px;
}

.e-getstart-banner h2 {
  color: #F9F9F9;
  font-size: 36px;
  line-height: 48px;
}

.e-getstart-banner p {
  color: #F9F9F9;
  font-size: 16px;
}

.e-getstart-button {
  padding: 15px 20px;
  background-color: #FFFFFF;
  border-radius: 4px;
  font-size: 14px;
  line-height: 18px;
  font-feature-settings: 'ss01' on, 'ss03' on;
  color: #0461D1;
  font-weight: 600;
}

/*------------------------------------------------------------------

 - Join Aliceblue Banner

-------------------------------------------------------------------*/
.e-joinaliceblue-banner {
  background: #0461D1;
  background-image: url("./images/joinalicebluebg.svg");
  background-position: center;
  background-repeat: no-repeat;
  padding-bottom: 100px;
}

.e-joinaliceblue-banner h2 {
  font-weight: 800;
  font-size: 36px;
  line-height: 48px;
  font-feature-settings: 'ss03' on, 'ss01' on;
  color: #F4F9FF;
}

.e-joinaliceblue-banner p {
  color: #F4F9FF;
  font-size: 16px;
  line-height: 28px;
}

.e-joinaliceblue-btn {
  background: #FFFFFF;
  border-radius: 4px;
  color: #0461D1;
  font-feature-settings: 'ss01' on, 'ss03' on;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  padding: 15px 20px;
  transition: ease-in-out 0.5s;
}

.e-joinaliceblue-btn:hover {
  background: #E2E5EA;
}

.e-bgimagesignal {
  position: absolute;
  left: 100px;
  top: -60px;
}

.e-bgimagewired {
  position: absolute;
  left: 300px;
}

.e-joinaliceblue-content {
  margin: 0 auto;
}

.e-bgimagepercentage {
  position: absolute;
  top: -30px;
  right: 90px;
}

.e-bgimagecell {
  position: absolute;
  bottom: -90px;
  right: 300px;

}

/*------------------------------------------------------------------

 - Welcome Trade store Banner

-------------------------------------------------------------------*/
.e-welcomebanner {
  background: #0461D1;
  background-image: url("../../assets/images/welcomeBanner/bgimg.svg");
  background-repeat: no-repeat;
  padding-bottom: 90px;
  border-radius: 8px;
}

.e-welcomebanner h2 {
  font-weight: 800;
  font-size: 40px;
  line-height: 56px;
  font-feature-settings: 'ss03' on, 'ss01' on;
  color: #FFFFFF;
}

.e-welcomebanner p {
  color: #FFFFFF;
  font-size: 16px;
  line-height: 21px;
  font-weight: 200;
}

.e-welcomebanner-img {
  position: absolute;
  border-radius: 8px;
  top: -48px;
  left: 0;
}

.e-welcomebanner-grn {
  position: absolute;
  bottom: -100px;
  right: 30px;

}

.e-welcomebanner-blue {
  position: absolute;
  bottom: -40px;
  right: 400px;
}

.e-welcomebanner-org {
  position: absolute;
  right: 450px;
  bottom: 130px;
}

.e-welcomebgimg {
  position: absolute;
  left: 0;
  bottom: -100px;
  border-radius: 8px;
}

/*just start banner*/
.e-juststart-banner {
  background: #FC9C66;
  background-image: url("../../assets/images/GetStartBanner/juststartbg.svg");
  background-repeat: no-repeat;
  padding-bottom: 65px;
  border-radius: 8px;
  background-size: cover;
}

.e-juststart-banner h2 {
  color: #F9F9F9;
  font-size: 36px;
  line-height: 48px;
}

.e-juststart-banner p {
  font-size: 16px;
  line-height: 28px;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  color: #F9F9F9
}

.e-img {
  height: 353px;
  margin-top: 20px;
}

.e-img-thum {
  width: 160px;
  height: 120px;
  border-radius: 8px;
  object-fit: cover;
}

.e-img-thum:hover {
  cursor: pointer;
  opacity: 0.8;
}

.e-slidnav-img {
  width: 100%;
  height: 360px;
  border-radius: 8px;
  object-fit: cover;
}

.e-video-wrapp:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: inline-block;
  background: linear-gradient(180deg, rgba(44, 44, 44, 0) 0%, rgba(0, 0, 0, 1.65) 100%);
  border-radius: 8px 8px 0px 0px !important;
}

.slick-dots {
  text-align: left !important;
}

.slick-dots li {
  margin: 0px -2px !important;
}

.slick-dots li button:before {
  font-size: 9px !important;
}

/*.slick-slide img {
	background: #F7F7F7;
	object-fit: contain;
}*/
.e-slidnav-img,
.e-slidnav-img {
  background: #F7F7F7;
  object-fit: contain;
}

.slick-slide img {
  object-fit: contain;
}

.e-text {
  border: 1px solid #EAEAEA;
  border-radius: 4px;
  width: 100%;
  padding: 8px 8px 8px 12px;
}

.e-text:focus,
.e-form-textarea:focus {
  border: 1px solid #0461D1;
}

.e-text::placeholder,
.css-1wa3eu0-placeholder,
.e-form-textarea::placeholder {
  font-size: 14px;
  color: #999999 !important;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  font-weight: 200;
  font-family: HK Grotesk;
  line-height: 20px;
}

.css-1wa3eu0-placeholder {
  margin: 0 !important;
  padding-left: 3px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.e-error-input {
  border-color: red;
}

.e-error-msg-s {
  font-size: 11px;
  font-weight: 200;
}

.e-error-msg {
  color: #bb2626;
  background-color: #f5d1d1;
  font-size: 14px;
  padding: 5px 10px 5px;
  border-radius: 5px;
}

.e-success-msg {
  color: #11865c;
  background-color: #d1f5e3;
  font-size: 14px;
  padding: 5px 10px 5px;
  border-radius: 5px;
}

.e-form-btn {
  background: #0461D1;
  border-radius: 4px;
  padding: 15px 20px;
  color: #FFFFFF;
  font-size: 14px;
  line-height: 18px;
  font-family: HK Grotesk;
  font-weight: 400;
  transition: ease-in-out 0.5s;
  position: relative;
}

.e-form-btn img {
  width: 16px;
  position: absolute;
  right: -30px;
  top: 15px;
}

.e-form-btn[disabled] {
  opacity: 0.7;
}

.e-form-btn[disabled]:hover {
  background: #0461D1;
}

.e-form-btn:hover {
  background: #0C3E90;
}

.e-form-textarea {
  border: 1px solid #EAEAEA;
  border-radius: 4px;
  width: 100%;
  padding: 8px 8px 55px 8px;
  resize: none;
}

.e-form-head {
  color: #444444;
  font-size: 16px;
  line-height: 21px;
  font-weight: 800;
}

.e-form-paragraph {
  color: #444444;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.css-1okebmr-indicatorSeparator {
  display: none;
}

.e-home-form .css-26l3qy-menu {
  font-feature-settings: 'ss01' on, 'ss03' on;
  color: #444444;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.e-home-form .css-1uccc91-singleValue {
  font-feature-settings: 'ss01' on, 'ss03' on;
  color: #444444;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.e-home-form {
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 35px;
  background: #FFFFFF;
}

.e-contactform-paragraph {
  color: #444444;
  font-size: 16px;
  line-height: 28px;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  padding-right: 100px;
}

.e-contactform-head {
  font-weight: 800;
  font-size: 40px;
  line-height: 56px;
  font-feature-settings: 'ss03' on, 'ss01' on, 'liga' off;
  color: #444444;
}

.e-join-ab-sec .modal-dialog,
.e-slick-nav .modal-dialog {
  max-width: 700px;
}

.e-react-player {
  width: 100% !important;
  height: 377px ! important;
}

.e-contact-nb {
  display: block;
  color: #444444;
  opacity: 0.67;
  font-size: 11px;
  line-height: 18px;
}

.e-home-slider-sec {
  padding: 60px 0px 60px 0px;
  background: #F7FBFF;
  height: 344px;
}

.e-home {
  /* background-image: url("./images/homebg.png");
  background-repeat: no-repeat;
  background-position: 68% 50%;
  padding-top: 40px; */
}

.e-home-head {
  font-weight: 800;
  font-size: 24px;
  line-height: 31px;
  color: #444444;
}

.e-home-subhead {
  font-size: 25px;
  font-weight: 200;
}

.e-home-h5 {
  /* color: #444444; */
  letter-spacing: 0.2em;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 18px;
    color: #444444;
    font-weight: 600;
    margin-top: 35px;
}

.e-form-rule {
  background: #0461D1;
  width: 30px;
  height: 3px;
  position: absolute;
  top: -16px;
  left: 15px;
}

.e-home-downarrow {
  animation: bounce 2s infinite;
  position: absolute;
  top: -65px;
  right: 80px;
}

.e-home-trades-img {
  width: 131px;
}

.e-home-serive-sec {
  padding: 70px 0px 300px 0px;
}

.e-home-vedio-button {
  padding: 15px 25px 15px 25px;
  background-color: #0461D1;
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  transition: ease-in-out 0.3s;
}

.e-home-vedio-button:hover {
  background: #0C3E90;
}

.e-home-media-head {
  line-height: 160%;
  color: #444444;
  font-weight: 700;
  font-size: 18px;
  font-family: HK Grotesk;
}

.e-home-service-p {
  color: #444444;
  font-size: 16px;
  line-height: 28px;
  font-weight: 200;
}

.e-media-card {
  box-shadow: 0px 16px 32px rgba(221, 230, 237, 0.54);
  border-radius: 8px;
  background: #FFFFFF;
}

.e-home-serive-sec {
  position: relative;
  background-image: url("./images/homebgwave.svg"), url("./images/homebgwave1.svg");
  background-position: 40% 90%, 40% 105%;
  background-repeat: no-repeat;

}

.e-home-plystore {
  margin-top: 150px;
}

.e-home-prdt-section {
  padding: 50px 0px 150px 0px;
}

.e-home-prdt-section .e-form-rule {
  top: -10px;
  left: 15px;
}

.e-home-product-card,
.e-home-product-card-sm {
  background: #FFFFFF;
  box-shadow: 0px 16px 32px rgba(221, 230, 237, 0.62);
  border-radius: 8px !important;
}

.e-home-product-card {
  padding: 80px 32px 80px 40px;
}

.e-home-product-card-sm {
  padding: 10px 15px;
  width: 130px;
}

.e-prdt-col-bg {
  position: absolute;
  top: 100px;
  left: 100px;
}

.e-pd-log {
  padding: 15px 61px !important;
}

.e-fiftypluscol-bg {
  position: absolute;
  bottom: 40px;
  right: 10px;
}

.e-hm-card-span {
  font-weight: 800;
  font-size: 36px;
  line-height: 47px;
  color: #DDDDDD;
}

.e-hm-card-span sub {
  font-size: 15px;
}

.e-hm-card-h6 {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #444444;
}

.e-home-smcs-crd-bg {
  position: absolute;
  top: 0;
  right: 5px;
}

.e-primary-blue-btn {
  padding: 15px 25px 15px 25px;
  background-color: #0461D1;
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  transition: ease-in-out 0.5s;
}

.e-home-prdt-section .e-primary-blue-btn:hover,
.e-home-explore-prdt-sec .e-primary-blue-btn:hover {
  background: #0C3E90;
}

.e-home-explore-prdt-sec .e-primary-otln-btn:active {
  outline: none !important;
}

.e-home-explore-prdt-sec {
  padding: 80px 0px 200px 0px;
}

.e-marketDetails-card {
  border-radius: 8px !important;
}

.e-visit-market-arrow {
  position: absolute;
  top: -25px;
  right: -15px;
  transition: ease-in-out 0.5s;
}

.e-visit-market {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.05rem;
  color: #0461D1;
  transition: ease-in-out 0.5s;
}

.e-visit-market:hover {
  text-decoration: none;
  color: #0C3E90;

}

.e-visit-market:hover .e-visit-market-arrow {
  transform: translateX(3px);
}

.e-home-product-desc {
  margin-top: 100px;
}

.e-h-product-card-right {
  top: 140px;
}

.e-home-pr-card-col {
  background-image: url("./images/hometradebg3.png");
  background-repeat: no-repeat;
  background-position: 50% 90%;
}

.e-home-prdt-bgimg {
  position: absolute;
  top: 170px;
  left: 200px;
}

.e-home-prdt-bgimg1 {
  position: absolute;
  top: 200px;
  left: 20px;
}

.e-join-ab-sec {
  padding: 200px 0px 0px 0px;
}

.e-home-card-sec {
  padding: 70px 0px 150px 0px;
}

.e-home-card {
  background: #FFFFFF;
  box-shadow: 0px 16px 32px rgba(221, 230, 237, 0.62);
  border-radius: 8px;
  border: none !important;
}

.e-card-p {
  font-size: 16px;
  line-height: 28px;
  color: #464646;
  font-weight: 200;
  margin-bottom: 0;
}

.e-dot {
  font-size: 24px;
  font-weight: 800;
  vertical-align: text-bottom;
}

.e-card-explore {
  color: #0461D1;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.05em;
  font-feature-settings: 'ss01' on, 'ss03' on;
  font-weight: 800;
  transition: ease-in-out 0.5s;
}

.e-card-explore:hover {
  text-decoration: none;
  color: #0C3E90;
}

.e-card-explore:hover .e-card-arrow {
  transform: translateX(5px);
  transition: ease-in-out 0.2s;
  color: #0C3E90;
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

/*marketplace*/
.e-marketplace-search {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #D8D3D3;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px 20px 8px 35px;
  background-image: url("./images/searchicon.svg");
  background-repeat: no-repeat;
  background-position: 5% 50%;
}

.e-marketplace-search:focus {
  border: 1px solid #0461D1;
}

.e-marketplace-search::placeholder {
  color: #444444;
  font-size: 14px;
  line-height: 18px;
}

.e-close-btn {
  width: 13px;
  height: 13px;
  background-image: url("../images/header/close.png");
  background-position: center;
  background-size: cover;
  display: inline-block;
  position: absolute;
  cursor: pointer;
}

.e-close-btn:hover {
  opacity: .6;
}

.e-marketplace-search-wrap .e-close-btn {
  right: 15px;
  top: 13px;

}

.e-radio-title {
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  color: #444444;
}

.e-radio-label {
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  cursor: pointer;
  text-transform: capitalize;
}

.MuiSvgIcon-root {
  width: 20px !important;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #0461D1 !important;
}

.MuiIconButton-label {
  margin-left: -10px;
}

.MuiRadio-root {
  padding: 0px 10px !important;
}

.e-marketplace-card-sec {
  padding-bottom: 100px;
}

.e-marketplace-playbanner-sec {
  padding-bottom: 200px;
}

.e-card-link {
  color: rgba(68, 68, 68, 0.67);
  font-size: 14px;
  line-height: 18px;
  font-feature-settings: 'ss01' on, 'ss03' on;
  font-family: HK Grotesk;
  font-weight: 600;
  position: relative;
  text-decoration: none;
}

.css-26l3qy-menu {
  z-index: 99 !important;
  font-size: 14px;
}

.e-marketplace-radio-heading {
  padding: 10px 20px 4px 5px;
  background: #FAFAFA;
  border: 1px solid #EEEEEE;
  border-radius: 2px;
  width: 100%;
}

.e-active {
  color: #0461D1;
  text-decoration: underline;
}

.e-card-link:hover {
  text-decoration: none;
}

.e-filter-select {
  box-shadow: 0px 16px 32px rgba(221, 230, 237, 0.54);
  border-radius: 8px;
  width: 250px;
  padding: 0;
}

.e-select-label {
  color: #464646;
  font-size: 12px;
  line-height: 20px;
  font-weight: 800;
}

.e-marketplace-card-sec .css-1l6guga-control {
  border-radius: 8px !important;
  border-color: #F8F8F8;
  padding: 0;
}

.e-marketplace-card-sec .css-peowp8-control {
  padding: 0 !important;
  border-radius: 8px !important;
  border-color: #F8F8F8;
}

.e-marketplace-card-sec .css-1uccc91-singleValue {
  font-feature-settings: 'ss01' on, 'ss03' on;
  color: #444444;
  font-size: 14px;
  line-height: 18px;
}

/*contact page*/
.e-contactdesk-head {
  font-weight: 800;
  font-size: 18px;
  line-height: 21px;
  color: #444444;
}

.e-contactdesk-p-blue,
.e-contactdesk-p {
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
  margin-block-end: auto;
}

.e-contactdesk-p-blue {
  color: #0461D1;
}

.e-contactdesk-p-blue a {
  color: #0461D1;
}

.e-contact-desk {
  background: #FFFFFF;
  box-shadow: 0px 16px 32px rgba(221, 230, 237, 0.62);
  border-radius: 8px;
}

.e-contact-desk .e-form-rule {
  left: 240px;
  top: 25px;
}

.e-contact-playbanner-sec {
  padding: 150px 0px 200px 0px;
}

/*marketplace details*/
.e-marketDetails-header-sec {
  background: #F7FBFF;
  padding: 40px 0px 20px 0px;
}

.e-marketDetails-link,
.e-marketDetails-link-color {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  font-feature-settings: 'ss03' on, 'ss01' on;
  color: black;
  opacity: 0.8;
}

.e-marketDetails-link-color {
  opacity: 0.50;
}

.e-marketDetails-link:hover {
  color: #0461D1;
  text-decoration: none;
}

.e-marketDetails-link-color:hover {
  cursor: default;
  text-decoration: none;
  color: black;
  opacity: .5;
}

.e-prod-detail-illustration {
  max-width: 200px;
  max-height: 200px;
  object-fit: cover;
}

.e-marketDetails-h5 {
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 18px;
  color: #444444;
  font-weight: 600;
  margin-top: 35px;
}

.e-marketDetails-h4 {
  font-weight: 800;
  font-size: 40px;
  line-height: 56px;
  color: #444444;
}

.e-marketDetails-review {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  font-feature-settings: 'ss01' on, 'ss03' on;
  color: #0461D1;
  vertical-align: sub;
  transition: ease-in-out 0.5s;
}

.e-marketDetails-review:hover {
  cursor: pointer;
  color: #0C3E90;

}

.e-marketDetails-review-img {
  height: 24px;
}

.e-mrktdtls-review-span {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  font-feature-settings: 'ss01' on, 'ss03' on;
  color: #0461D1;
}

.e-add-review-modal textarea {
  min-height: 100px;
}

.e-brdr-right-span {
  border-right: 1px solid #444444;
  padding: 3px 10px 3px 0px;
}

.e-prdtdtls-card .accordion {
  margin-left: 8px;
}

.e-prdtdtls-card .e-accordion {
  background: #FFFFFF;
  border-radius: 2px;
}

.e-prdtdtls-card .accordion>.card>.card-header {
  border: 1px solid #0461D1 !important;
  background-color: #eef6ff !important;
  margin-bottom: 0;
  padding: 8px 5px 8px 15px;
}

.e-prdtdtls-card .accordion .btn-block {
  padding: 0;
  background-position: 100% 50% !important;
}

.e-prdtdtls-card .accordion .btn.btn-link {
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  color: #444444;
}

.e-prdtdtls-card .accordion .card-body {
  background: #fafafa !important;
  border: 1px solid #efefef;
  border-radius: 4px;
  margin-top: 20px;
  padding: 10px 10px 5px 10px;
}

.e-bookmark-icn {
  height: 20px;
  width: 20px;
  /*margin-left: 20px;*/
  background-image: url("../images/marketplaceDetails/fav.svg");
  background-position: center;
  background-size: contain;
  display: inline-block;
  cursor: pointer;
  background-repeat: no-repeat;
  top: 7px;
  position: relative;
}

.e-bookmark-icn.e-bookmark-fill {
  background-image: url("../images/marketplaceDetails/fav-active.svg");
}

.e-bookmark-icn:hover {
  opacity: .8;
}

.e-bookmark-fill {
  color: red;
}

.e-bookmark-link {
  color: black;
}

.e-col {
  position: relative;
}

.e-ac-open-card {
  background: #FFFFFF;
  box-shadow: 0px 16px 32px rgba(221, 230, 237, 0.62);
  border-radius: 8px;
}

.e-marketDetails-p {
  font-size: 16px;
  line-height: 24px;
  color: #444444;
}

.e-marketDetails-banner-sec {
  padding: 0px 0px 100px 0px;
}

.e-marketDetails-card-sec {
  padding-bottom: 100px;
}

.e-mrktplc-playbanner-sec {
  padding: 90px 0px 200px 0px;
}

.e-mrktplc-rule {
  width: 32px;
  height: 4px;
  background-color: #0461D1;
  margin-left: 0;
}

.e-cardslider-col {
  margin-left: -15px;
}

.e-cardslider-col .e-primary-blue-btn {
  padding: 8px 19px !important;
}

.e-card-head {
  position: relative;
  border-radius: 8px 8px 0px 0px;
  min-height: 150px;
}

.e-card-overlay {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 42.29%, rgba(0, 0, 0, 0.30) 100%);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.e-abcard-hed {
  background-color: #B8FFC0 !important;
}

.e-optimum-hed {
  background-color: #DDD6FF !important;
}

.card-logo {
  max-height: 30px;
  object-fit: contain;
  position: relative;
  z-index: 9;
}

.card-illustration {
  position: relative;
  z-index: 9;
  max-height: 90px;
  max-width: 105px;
}

.e-card-type-tag {
  color: #0461D1;
  display: inline;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  background: #EBF4FF;
  padding: 5px 10px;
  margin-right: 8px;
  border-radius: 10px;
}

.e-card-btn-wrap button {
  padding: 9px 0 !important;
  text-align: center;
  width: 100%;
}

.e-card-btn-wrap .e-primary-blue-btn {
  padding: 9px 1px !important;
}

.e-card-rating {
  padding-top: 12px;
  position: relative;
  z-index: 2;
}

.e-card-rating span {
  position: relative;
  top: 2px;
  font-size: 12px;
  color: #fff;
}

.e-marketDetails-card {
  background: #FFFFFF;
  box-shadow: 0px 16px 32px rgba(221, 230, 237, 0.54);
  border-radius: 8px;
}

.e-crd-sldr {
  width: calc(100% + 30px);
  padding: 0 15px;
  margin-left: -15px;
  overflow: hidden;
}

.e-mrktplc-card-title {
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  color: #444444;
}

.e-mrktplc-card-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  height: 40px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.e-mrktplc-product-plan {
  font-size: 12px;
}

.e-prdt-free-grn {
  color: #5ce36a;
}

.e-mrktplc-card-p {
  margin-block-end: auto;
}

.e-marketDetails-card .e-primary-blue-btn {
  padding: 8px 20px;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #FFFFFF;
  transition: ease-in-out 0.3s;
}

.e-marketDetails-card .e-primary-blue-btn:hover {
  background: #0e2d5f;
}

.e-marketDetails-card .e-primary-otln-btn {
  background: #FFFFFF;
  box-shadow: 0px 16px 32px rgba(221, 230, 237, 0.54);
  padding: 7px 20px;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #0461D1;
  transition: ease-in-out 0.4s;
}

.e-marketDetails-card .e-primary-otln-btn:hover {
  background-color: #0461D1;
  color: #FFFFFF;
}

.e-mrktplce-usrtype {
  background: #EBF4FF;
  border-radius: 100px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #0461D1;
  padding: 5px 8px;
}

.e-filter-img {
  width: 30px;
  height: 30px;
}

.e-filter-btn {
  background-color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  color: #0461D1;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  padding: 10px;
  display: none;
}

.e-filter-btn:hover {
  transform: translateY(-3px);
  transition-delay: 0.1s;
}

.e-market-fil {
  height: 100%;
  overflow: hidden;
  background-color: #ffffff;
  transition: ease-in-out 1s;
}

.e-filter-fly {
  height: 100% !important;
  top: 0;
  right: 0 !important;
  transition: ease-in-out 1s;
  z-index: 10;
}

.e-mrktplc-close {
  display: none;
}

/*Market place details product details tab*/
.e-prod-about-section {
  position: relative;
  margin-top: 100px;
}

.e-prod-about-section .e-form-rule {
  left: 0;
  top: -30px;
}

.slick-next:before,
.slick-prev:before {
  color: transparent !important;
}

.e-wrapper {
  width: 100%;
  height: 360px;
  position: relative;
}

.e-video-thump {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.e-modal-overlay {
  opacity: 0.8;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: black;
  width: 100%;
  height: 100%;
  transition: all ease;
  z-index: 20;

}

.e-wrapper iframe {
  display: none;
}

.e-play-btn {
  position: absolute;
  z-index: 666;
  bottom: 125px;
  left: 300px;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border: 0;
}

.e-prdtdtls-smallcase-h5 {
  font-weight: 800;
  font-size: 24px;
  line-height: 31px;
  color: #444444;
}

.e-prdtdtls-p,
.e-prdtdtls-usp p {
  font-size: 16px;
  line-height: 28px;
  color: #424242;
  font-weight: 300;
}

.e-prdtdtls-usp h6 {
  font-weight: 600;
  color: #464646;
}

.e-prdtdtls-usp .e-card-explore {
  font-size: 16px;
  font-weight: 600;
}

.e-box-span {
  background: #EBF4FF;
  border: 1px solid #0461D1;
  border-radius: 4px;
  font-size: 12px;
  line-height: 20px;
  color: #0461D1;
  padding: 3px 6px;
}

.e-smallcase-bnft {
  padding: 100px 0px 80px 0px;
}

.e-smallcase-bnft .e-form-rule {
  position: relative;
  margin-left: -15px;
  margin-block-end: -10px;
}

.e-smallcase-bnft ul {
  padding-left: 15px;
}

.e-prdtdtls-bold {
  font-weight: 600;
  color: #464646;
}

.e-smallcase-bnft p {
  margin-block-end: -10px;
  color: black;
}

.e-prdtdtls-card {
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.08);
  padding: 40px 30px;
  background-image: url("./images/prdtcardbgimg.svg");
  background-position: 100% 6%, 95% 6%;
  background-repeat: no-repeat;
}

.e-prdtdtls-card .e-form-rule {
  top: 8px;
  left: 30px;
}

.e-prdtdtls-card .e-features-list {
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #000;
  text-transform: capitalize;
}

.e-smallcase-bnft .e-features-list {
  font-size: 16px;
  line-height: 28px;
  font-weight: 300;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  color: #000;
  text-transform: capitalize;
}

.e-prdtdtls-card ul {
  padding-left: 15px;
}

.e-prdtdtls-card .e-dot {
  vertical-align: revert;
}

.e-prdtdtls-card .e-card-p {
  font-size: 14px;
  color: black;
}

.e-card-p.e-blue {
  color: #0461D1;
  font-weight: 400;
  padding-top: 5px;
  background: #c1d3fd;
  padding: 2px 15px;
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #0461D1;
  opacity: .9;
  margin-top: 21px;
}

.e-prdtdtls-ofr {
  margin-block-end: 5px;
}

.e-grn-span {
  color: #13D726;
  font-weight: 600;
}

.e-prdtls-subplan {
  background: #FAFAFA;
  box-shadow: inset 0px 0px 2px #D9D9D9;
  border-radius: 4px;
}

.e-prdtls-subplan span {
  display: inline-block;
}

.e-prdtdtls-card .css-1l6guga-control {
  background-color: #EEF6FF;
  border-color: #78B4FD;
  border-radius: 2px;
}

.e-product-details .css-26l3qy-menu,
.e-product-details .css-1uccc91-singleValue {
  text-transform: capitalize !important;
}

.e-prdtdtls-card .e-primary-blue-btn,
.e-prdtdtls-card .e-primary-grn-btn {
  padding: 15px 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  font-feature-settings: 'ss01' on, 'ss03' on;
  color: #FFFFFF;
  width: 158px;
  display: inline-block;
  transition: ease-in-out 0.5s;
  margin-top: 10px;
}
.e-p-n-btn {
  width: 346px!important;
}

.e-prdtdtls-card .e-primary-otln-btn {
  background: #FFFFFF;
  box-shadow: 0px 16px 32px rgba(221, 230, 237, 0.54);
  padding: 13px 25px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #0461D1;
  transition: ease-in-out 0.4s;
}

.e-prdtdtls-card .e-primary-otln-btn:hover {
  background-color: #0461D1;
  color: #FFFFFF;
}

.e-prdtdtls-card .e-primary-grn-btn {
  background-image: url("./images/btn-grn-arrow.svg");
  background-position: 95% 15%;
  background-repeat: no-repeat;
}

.e-prdtld-ext {
  background-position: 95% 20%;
  background-repeat: no-repeat;
  padding: 15px 41px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  font-feature-settings: 'ss01' on, 'ss03' on;
  color: #FFFFFF;
  width: 158px;
  display: initial;
  transition: ease-in-out 0.5s;
  margin-top: 10px;
  background-color: #13D726;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
}

.e-product-details .e-prdtld-ext {
  margin-top: 0;
}

.e-dull-text {
  color: #6F8BA8;
  font-size: 11px;
}

.e-prdtld-ext.e-trial {
  padding: 15px 30px;
  text-align: center;
}

.e-prdtld-ext.e-extrnl-link {
  background-image: url("./images/btn-grn-arrow.svg");
}

.e-full-width {
  padding: 13px 145px !important;
}

.e-width-full {
  width: 90%;
  text-align: center;
}

.e-full-width-free {
  padding: 13px 138px !important;
}

.e-full-width-trail {
  padding: 13px 131px !important;
}

.e-full-button {
  width: 89% !important;
}

.e-prdtld-ext:hover {
  text-decoration: none;
  color: #FFFFFF;
  background-color: #1e9229;
}

.e-prdtdtls-card .e-primary-blue-btn:hover {
  background-color: #2756A2;
}

.e-prdtdtls-card .e-primary-grn-btn:hover {
  background-color: #06BA2F;
}

.e-indent {
  margin-left: 10px;
}

.e-platform-card {
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.08);
  padding: 40px 30px;
}

.e-platform-card .e-form-rule {
  top: 12px;
  left: 30px;
}

.e-blue-p {
  color: #0461D1;
  font-size: 14px;
}

.e-platform-card p {
  font-size: 14px;
  line-height: 18px;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  color: #424242;
}

.e-product-details .e-refer-card,
.e-product-details .e-support {
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.08);
  padding: 40px 30px;
}

.e-refer-card .e-form-rule {
  top: 12px;
  left: 30px;
}

.e-earn-p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  font-feature-settings: 'ss03' on, 'ss01' on;
  color: #424242;
  padding-right: 10px;
}

.e-refer-card .e-text {
  border-radius: 4px 0px 0px 4px;
  padding-right: 40px;
  border-right: none;
  width: 100%;
  padding-right: 100px;
}

.e-refer-input-wrap button {
  position: absolute;
  right: -18px;
  top: 0;
  padding: 13px 20px !important;
  cursor: pointer;
}

.e-refer-card .e-primary-blue-btn {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
  border-radius: 0px 4px 4px 0px;
  padding: 10px 20px;
  width: 130px;
}

.e-refer-card .e-primary-blue-btn:hover {
  background-color: #2756A2;
}

.e-refer-card .e-primary-blue-btn[name="Clear"] {
  background-color: red;
}

.e-refer-card .e-primary-blue-btn[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
}

.e-refer-card .e-primary-blue-btn[disabled]:hover {
  background: #0461D1;
}

.e-generated-head {
  font-weight: bold;
  font-size: 14px;
  line-height: 28px;
  font-feature-settings: 'ss03' on, 'ss01' on;
  color: #444444
}

.e-copy-btn {
  /*font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	font-feature-settings: 'ss01' on, 'ss03' on;
	color: #0461D1;
	padding: 8px;
	text-align: center;
	border: 1px solid #0461D1;
	border-radius: 4px;
	width: 80px;*/
  background-color: white;

}

.e-earn-link {
  color: #0461D1 !important;
}

.e-copy-btn:focus {
  border: 1px solid #0461D1 !important;
}

.e-product-details .e-support p {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-block-end: 5px;
}

.e-prod-report-wrap {
  padding-bottom: 150px;
}

.e-support-link {
  font-size: 14px;
  line-height: 18px;
  color: #0461D1;
  letter-spacing: 0.05em;
  font-weight: 800;
  transition: ease-in-out 0.5s;

}

.e-support-img {
  height: 120px;
}

.e-support-link:hover {
  text-decoration: none;
  color: #0C3E90;
}

.e-support-link:hover .e-card-arrow {
  transform: translateX(5px);
  transition: ease-in-out 0.3s;
  color: #0C3E90;
}

.e-product-modal .close {
  font-weight: 300;
  font-size: 29px;
}

.e-product-modal .e-terms-wrapper {
  height: 400px;
  overflow: auto;
  font-size: 13px;
}

.e-product-modal .e-terms-wrapper ul{
 padding: 0;
}
.e-product-modal .e-terms-wrapper ul li{
  margin-top: 5px;
}

.e-product-modal h5 {
  font-weight: 800;
  font-size: 18px;
  line-height: 200%;
  color: #444444;
  font-family: HK Grotesk;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
}

.e-product-modal .modal-header {
  border-bottom: 0;
}

.e-product-modal p {
  font-family: HK Grotesk;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  color: #444444;
}

.e-product-modal p.e-offer-price {
  font-weight: bold;
}

.e-product-modal p.e-offer-price span {
  color: #13D726;
}

.e-product-modal .modal-content {
  padding: 5px 15px 15px;
}

.e-product-modal .modal-footer {
  border-top: 0;
}

.e-product-modal .modal-footer button {
  margin-right: 5px !important;
}

.e-product-modal .e-modal-total-price {
  font-size: 13px;
  position: absolute;
  left: 5px;
}
.e-product-modal .e-confirm-text{
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  color: #444444;
}
.e-product-modal input[type=checkbox]{
  width: 20px;
  height: 20px;
  margin-right: 8px;
  cursor: pointer;
}
.e-product-modal .e-check-box{
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #2C2C2E;
align-items: center;
  display: flex;
  cursor: pointer;
}
.e-product-modal .e-check-box label{
  padding-top: 8px;
  cursor: pointer;
}
.e-product-modal .e-check-box a{
  padding-left: 4px;
}
.e-product-modal .e-cancel-btn{
  padding: 11px 25px 11px 25px !important;
}
.e-product-modal .e-footer-btns{
  background: #FFFFFF;
box-shadow: 0px -2px 20px rgba(0, 0, 0, 0.1);
padding: 10px 0px 10px 0px;

}
/*Market place details review tab*/

progress {
  width: 60%;
  height: 4px;
}

progress::-webkit-progress-bar {
  background-color: #F2F2F2;
}

progress::-webkit-progress-value {
  background-color: #FBCA1E;
}

.e-rview-pbar {
  font-size: 12px;
  line-height: 16px;
  font-feature-settings: 'ss01' on, 'ss03' on;
  color: #444444;
  margin-block-end: 10px;
}

.e-marketDetailsReview-sec {
  padding: 10px 0px 200px 0px;
}

.e-marketDetailsReview-sec h1 {
  font-weight: 800;
  font-size: 36px;
  line-height: 47px;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  color: #444444;
}

.e-trade-rating-p {
  font-size: 12px;
  line-height: 16px;
  font-feature-settings: 'ss01' on, 'ss03' on;
  color: black;
}

.e-rview-no {
  font-size: 12px;
  line-height: 16px;
  font-weight: 200;
  font-feature-settings: 'ss01' on, 'ss03' on;
  color: #464646;
}

.e-review-prdtdtls-review-sec {
  background: #F6FBFF;
  border-radius: 4px;
}

.e-marketDetailsReview-sec .css-1l6guga-control {
  border-radius: 8px !important;
  border-color: #F8F8F8;
  padding: 0;
}

.e-marketDetailsReview-sec .css-peowp8-control {
  padding: 0 !important;
  border-radius: 8px !important;
  border-color: #F8F8F8;
}

.e-marketDetailsReview-sec .css-1uccc91-singleValue {
  font-feature-settings: 'ss01' on, 'ss03' on;
  color: #444444;
  font-size: 14px;
  line-height: 18px;
}

.e-marketDetailsReview-sec .css-9gakcf-option {
  background-color: #ffffff;
  color: #0461D1;
}

.e-write-review {
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: #0461D1;
  transition: ease-in-out 0.5s;
}

.e-write-review:hover {
  text-decoration: none;
  color: #0C3E90;
  cursor: pointer;
}

/*review card*/
.e-review-card {
  background: #FFFFFF;
  box-shadow: 0px 16px 32px rgba(221, 230, 237, 0.54);
  border-radius: 8px;
}

.e-reviewer {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  object-fit: cover;
}

.e-review-card-star {
  height: 12px;
  margin-left: 40px;
}

.e-reviewer-name {
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  color: #444444;
}

.e-review-date {
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  color: #777676;
}

.e-review-content {
  font-size: 14px;
  line-height: 24px;
  color: black;
  margin-block-end: 5px;
  height: 120px;
  overflow: hidden;
}

.e-review-mark {
  background-color: #ffffff;
  color: #0461D1;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-align: left;
  transition: ease 1s;
}

.e-review-mark:hover {
  text-decoration: none;
  color: #0C3E90;
}

.e-read-more:hover {
  text-decoration: none;
  cursor: pointer;
  color: #2756A2;
}

/*review modal*/
.e-review-modal-closebtn {
  background-color: #ffffff;
}

.e-reviewmodal-head {
  font-weight: 800;
  font-size: 16px;
  line-height: 21px;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  color: #444444
}

.e-reviewmodal-p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  color: #444444;
}

.e-review-modal .e-text {
  resize: none;
  padding: 8px 8px 130px 8px;
}

.e-review-modal input[type=radio] {
  display: none;
}

.e-review-modal .e-primary-blue-btn {
  padding: 15px 15px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  font-feature-settings: 'ss01' on, 'ss03' on;
  color: #FFFFFF;
  margin-top: 15px;
}

.e-review-modal .e-primary-blue-btn[disabled] {
  opacity: 0.5;
}

.accordion .btn.btn-link[aria-expanded="false"] {
  background-image: url("./images/accordionArrow.svg");
  background-position: 95% 50%;
  background-repeat: no-repeat;
  transition: ease-in-out 0.2s;

}

.accordion .btn.btn-link[aria-expanded="true"] {
  background-image: url("./images/accordionup.svg");
  background-position: 95% 50%;
  background-repeat: no-repeat;
  transition: ease-in-out 0.2s;
}

.accordion .btn.btn-link {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  font-feature-settings: 'ss03' on, 'ss01' on;
  color: #464646;
  margin-left: -10px;
}

.accordion .btn:focus {
  box-shadow: none;
}

.accordion .btn-link {
  text-decoration: none;
}

.accordion .btn-link:hover,
.accordion .btn-link:focus {
  text-decoration: none;
}

.e-accordion-head {
  border: none !important;
  background: #ffffff !important;
}

.accordion .card {
  border: none;
}

.accordion {
  border: none;
  margin-left: -45px;
}

.e-accordion-p {
  font-size: 16px;
  line-height: 24px;
  opacity: 0.87;

}

.e-accordion-col {
  /*position: absolute;*/
  /*width: 95%;
 	top: 0;
 	z-index: 10;*/
}

.e-col {
  /*position: relative;*/
}

.e-ac-open-card {
  background: #FFFFFF;
  box-shadow: 0px 16px 32px rgba(221, 230, 237, 0.62);
  border-radius: 8px;
}

.e-ac-open-card p {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #464646;
}

.e-faq-gethelp {
  padding: 200px 0px;
}

.e-faq-gethelp h1 {
  font-weight: 800;
  font-size: 36px;
  line-height: 48px;
  color: #464646;
  font-feature-settings: 'ss03' on, 'ss01' on;
  padding: 10px 225px;
}

.e-faqbg-blue,
.e-faqbg-orng,
.e-faqbg-grn {
  position: absolute;
}

.e-faqbg-blue {
  top: 1300px;
  left: 150px;
}

.e-faqbg-grn {
  top: 1100px;
  right: 150px;
}

.e-faqbg-orng {
  top: 1400px;
  right: 200px;
}

/*perfomance report*/
.e-report-card {
  background: #FFFFFF;
  box-shadow: 0px 16px 32px rgba(221, 230, 237, 0.54);
  border-radius: 8px !important;
  transition: ease-in-out 0.5s;
}

.e-report-card:hover {
  transform: translateY(-3px);
}

.e-report-head {
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  color: #444444;
  width: 270px;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.e-pdf-download {
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
  color: #0461D1;
}

.e-pdf-download:hover {
  text-decoration: none;
}

.e-report-query-sec {
  padding: 100px 0px 200px 0px;
}

.e-report-query-head {
  font-weight: 800;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  font-feature-settings: 'ss03' on, 'ss01' on;
  color: #444444;
}

/*resource page*/
.e-resouce-radio-heading {}

/*resourse page vedio tab*/

.e-videomodal-close {
  position: absolute;
  right: 15px;
  z-index: 2;
  background-color: #ffffff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.08);
  top: 15px;
}

.e-video-card-head {
  font-weight: 800;
  font-size: 14px;
  line-height: 21px;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  color: #444444;
  height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.e-videolist-thumb {
  width: 100%;
  max-height: 156px;
  border-radius: 8px 8px 0px 0px;
  object-fit: cover;
}

.e-vidoe-list-span {
  background: #EBF4FF;
  border-radius: 100px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 16px;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  color: #0461D1;
}

.e-videolist-card {
  background: #FFFFFF;
  box-shadow: 0px 16px 32px rgba(221, 230, 237, 0.54);
  border-radius: 8px !important;
  cursor: pointer;

  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.e-video-card:hover {
  -webkit-transform: translateY(-4px);
  transform: translateY(-4px);
}

.e-video-card-headersec {
  position: relative;
}

.e-video-card-headersec:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background: linear-gradient(180deg, rgba(44, 44, 44, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  border-radius: 8px 8px 0px 0px !important;
}

.e-vidoelist-play {
  width: 60px;
}

.e-videocard-icon {
  position: absolute;
  z-index: 1;
  left: 10px;
  bottom: 40px;
}

.e-vidoe-duration {
  position: absolute;
  z-index: 666;
  left: 30px;
  bottom: 0px;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border: 0;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
}

.e-ellipsis {
  display: inline-block;
  margin-top: -20px;
}

/*webinar list*/
.e-webinar-card-headersec {
  position: relative;
}

.e-webinar-card-headersec:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background: linear-gradient(180deg, rgba(44, 44, 44, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  border-radius: 8px 8px 0px 0px !important;
}

.e-webinar-play-btn {
  position: absolute;
  z-index: 666;
  left: 180px;
  bottom: 30px;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border: 0;
}

.e-webinar-schedule {
  position: absolute;
  z-index: 666;
  left: 80px;
  bottom: 0px;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border: 0;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
}

.e-webinar-live-stic {
  position: absolute;
  left: 15px;
  bottom: 10px;
  z-index: 1;
}

.e-webinar-videocam {
  position: absolute;
  left: 15px;
  bottom: 40px;
  z-index: 1;
}

.e-webinar-replay-icon {
  bottom: 10px;
}

.e-webinar-stick {
  position: absolute;
  z-index: 1;
  bottom: 30px;
  left: 20px;
  height: 25px;
}

.e-webinar-p {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  color: #444444;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.e-webinar-span {
  font-size: 12px;
  line-height: 16px;
  color: #444444;
  opacity: 0.8;
}

.e-webinar .e-primary-blue-btn {
  padding: 10px 20px;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  color: #FFFFFF;
  transition: ease-in-out 0.5s;
}

.e-webinar .e-primary-blue-btn:hover {
  background: #2756A2;
}

.e-webinar .e-vidoelist-play-btn {
  bottom: 235px;
}

.e-webinar .e-vidoe-duration {
  bottom: 200px;
  left: 100px;
}

/*resource blog tab*/
.e-blog-card-headersec {
  position: relative;
}

.e-blog-card-headersec:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 85%;
  display: inline-block;
  background: linear-gradient(180deg, rgba(44, 44, 44, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  border-radius: 8px 8px 0px 0px !important;
}

.e-blog-p {
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  color: #444444;
  height: 40px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.e-eye {
  position: relative;
  top: -2px;
}

.e-blog-views {
  bottom: 15px;
  position: absolute;
  left: 15px;
  z-index: 2;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
}

.e-blog .e-webinar-span {
  opacity: 0.9;
}

.e-blog-read-link {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: #0461D1;
  transition: ease-in-out 0.5s;
}

.e-blog-read-link:hover {
  text-decoration: none;
  color: #2756A2;
}

.e-blog-read-link:hover .e-card-arrow {
  transform: translateX(5px);
  transition: ease-in-out 0.3s;
}

/*resource video details*/
.e-video-details-main-sec {
  padding-bottom: 200px;
}

.e-video-details-main-sec .e-link-copied {
  width: 120px;
}

.e-videodetails-card .e-vidoe-list-span {
  width: 76px;
}

.e-vidoelist-play-btn {
  position: absolute;
  z-index: 666;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border: 0;
}

.e-videodetails-card {
  background: #FFFFFF;
  box-shadow: 0px 16px 32px rgba(221, 230, 237, 0.54);
  border-radius: 8px !important;
  padding: 100px 80px 30px 80px;
  margin-top: -60px;
}

.e-resource-video-cover {
  position: relative;
  z-index: 2;
  width: 100%;
  max-height: 520px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), ;
  border-radius: 8px 8px 0px 0px;
  object-fit: cover;
}

.e-video-detail-play:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background: linear-gradient(180deg, rgba(44, 44, 44, 0) 0%, rgb(0 0 0 / 100%) 100%);
  border-radius: 8px 8px 0px 0px !important;
  z-index: 2;
}

.e-video-detail-play .e-prdtld-ext {
  position: absolute;
  z-index: 3;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.e-resource-video {
  position: relative;
  z-index: 2;
  margin-top: 20px;
  height: 450px !important;
}

.e-render-tab-wrap {
  min-height: 500px;
}

.e-resouce-video-durtn {
  position: absolute;
  bottom: 20px;
  left: 30px;
  color: #ffffff;
  z-index: 3;
}

.e-video-desc {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #444444;
}

.e-resource-dtls-head {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  font-feature-settings: 'ss03' on, 'ss01' on;
  color: #ffffff;
  opacity: 0.7;
  vertical-align: middle
}

.e-videdtls .e-resource-dtls-head,
.e-webinar-dtls .e-resource-dtls-head {
  color: #444444;
}

.e-video-detail-play .e-vidoelist-play-btn {
  position: absolute;
  z-index: 666;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border: 0;
}

.e-video-detail-play .e-vidoelist-play-btn:hover {
  opacity: .8;
}

.e-vidoelist-play-btn.e-webinar-calander:hover {
  opacity: 1;
  cursor: initial;
}

/*resource webinar details*/
.e-webinar-time {
  position: absolute;
  color: #FFFFFF;
  z-index: 3;
  width: 100%;
  left: 0;
  top: 60%;
}

/*resource blog details*/
.e-blog-details-header {
  background: #0461D1;
  padding: 40px 0px 300px 0px;
}

.e-blog-details-header .e-marketDetails-link {
  color: #ffffff;
  opacity: 1;
}

.e-blog-details-header .e-marketDetails-link-color {
  color: #ffffff;
  opacity: 0.5;
}

.e-blogdtls-span {
  font-size: 12px;
  line-height: 16px;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
  padding: 5px 10px;
  border-radius: 100px;
}

.e-blogdtls-time {
  color: #FFFFFF;
  font-weight: 400;
}

.e-blogdtls-p,
.e-blogdtls-bgcolor-p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #444444;
  padding-bottom: 15px !important;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.e-blogdtls {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #444444;
  padding-bottom: 15px !important;
}

.e-blogdtls-des {
  font-size: 17px !important;
}

.e-blogdtls-percentage {
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  color: #0961d1;
}

.e-blogdtls-p {
  line-height: 200%;
}

.e-blogdtls-p.e-active {
  color: #0961d1;
}

.e-blogdtls-bgcolor-p {
  border-radius: 4px;
  background: #E4F0FF;
  padding: 5px 2px 5px 6px;
}

.e-blog-author {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #444444;
}

.e-blogdtls-time {
  font-size: 14px;
  font-weight: 300;
}

.e-blogcover-img {
  position: absolute;
  max-height: 320px;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.e-blogcover-h1 {
  font-weight: 800;
  font-size: 36px;
  line-height: 54px;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  color: #FFFFFF;
}

.e-blog-complete {
  width: 100%;
  height: 2px;
}

.e-blog-about progress[value]::-webkit-progress-value {
  background-color: #0461D1 !important;
}

.e-blog-author-img {
  height: 48px;
  width: 48px;
  object-fit: cover;
  border-radius: 100px;
}

.e-link-copied {
  display: block;
  margin-top: 10px;
  background: #d4efde;
  padding: 5px 15px 4px;
  border: 1px solid #178617;
  border-radius: 8px;
  font-weight: 500;
  color: #178617;
  text-align: center;
  font-size: 13px;
  z-index: 1;
}

.e-link-copied-s {
  display: inline-block;
  width: 150px;
}

.e-already-purchased {
  display: inline-block;
  background: #d4efde;
  padding: 7px 15px;
  border: 1px solid #178617;
  border-radius: 8px;
  font-weight: 500;
  color: #178617;
  text-align: center;
  font-size: 13px;
  z-index: 1;
  width: 100%;
}

.e-blog-content {
  z-index: 2;
}

.e-blog-content-fixed {
  position: fixed !important;
  top: 50px;
  width: 193px !important;
  animation: smoothScroll 1s;
}

.e-blog-content-right {
  position: fixed !important;
  top: 50px;
  left: 1140px;
  width: 193px !important;
  animation: smoothScroll 1s;
}

@keyframes smoothScroll {
  0% {
    transform: translateY(-40px);
  }

  100% {
    transform: translateY(0px);
  }
}

.e-blog-scroll p {
  font-size: 16px;
  font-weight: 300;
  line-height: 180%;
}

/*login*/
.e-login,
.e-signup,
.e-bnk-dtls-popup,
.e-lead {
  right: -40vw;
  height: 100%;
  overflow: hidden;
  position: fixed;
  background-color: #ffffff;
  transition: ease-in-out .4s;
  z-index: 10;
  top: 0;
}

.e-bnk-dtls-popup,
.e-lead {
  width: 40vw !important;

}

.e-login,
.e-signup {
  width: 34vw !important;
}

.e-login-fly {
  height: 100%;
  top: 0;
  right: 0;
  transition: ease-in-out .7s;
  z-index: 10;
}

.e-login-overlay {
  opacity: 0.8;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: black;
  width: 100%;
  height: 100%;
  transition: all ease;
  z-index: 9;
}

.e-login-closebtn {
  background-color: #ffffff;
  outline: none;
  border: none;
}

.e-login-closebtn:focus {
  outline: none;

}

.e-partner-login-input {
  border: 1px solid #D8D3D3;
  width: 100%;
  padding: 12px 20px 12px 15px;
  border-radius: 4px;
}

.e-prt-login {
  padding-top: 30px;
}

.e-partner-login-input:focus {
  border: 1px solid #0461D1;
}

.e-partner-login-input::placeholder {
  color: #444444;
  font-size: 14px;
  line-height: 18px;
}

.e-c-red {
  color: red !important;
}

.e-login-head {
  font-weight: 800;
  font-size: 24px;
  line-height: 31px;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  color: #444444;
}

.e-login p {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  color: #464646;
}

.e-login-p {
  margin-top: 100px;
}

.e-login .e-primary-blue-btn,
.e-login .e-primary-grn-btn {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  font-feature-settings: 'ss01' on, 'ss03' on;
}

.e-login .e-primary-blue-btn {
  padding: 19px 110px;
  margin-top: 10px;
}

.e-login .e-primary-grn-btn {
  padding: 19px 132px;
  margin-top: 15px;
}

.e-login .btn-outline-primary {
  padding: 19px 143px;
  margin-top: 10px;
  font-weight: 800;
  border: 1px solid #0461D1;
  font-size: 14px;
  line-height: 18px;
  font-feature-settings: 'ss01' on, 'ss03' on;
  color: #0461D1;
}

.e-login .btn-outline-primary:hover {
  background-color: #0461D1;
}

.e-login .btn-outline-primary:focus {
  outline: none !important;
}

.e-success-wrap h5 {
  font-weight: 800;
  font-size: 24px;
  line-height: 31px;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  font-family: HK Grotesk;
  color: #444444;
  padding-top: 60px;
}

.e-success-wrap p {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  color: #444444;
}

.e-success-wrap img {
  padding-top: 30px;
}

/*signup menu*/
.e-signup-head {
  font-weight: 800;
  font-size: 24px;
  line-height: 31px;
  color: #444444;
}

.e-singup-p {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
}

.e-signup .e-text {
  padding: 10px 30px 10px 7px;
}

.e-signup .e-form-select .css-1wa3eu0-placeholder {
  font-weight: 400 !important;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  color: #444444 !important;
}

.e-signup .e-form-select .css-g1d714-ValueContainer {
  padding: 8px;
}

.e-signup-btn {
  background: #0461D1;
  border-radius: 4px;
  padding: 11px 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
}

.e-signup-btn:hover {
  background: #2756A2;
}

.e-signup-btn[disabled] {
  opacity: 0.8;
  background: #0461D1;
}

.e-signup-nb {
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  opacity: 0.67;
  display: block;
}

.e-signup-nb b {
  opacity: 0.80;
}

.e-signup-nb a {
  color: #444444;
}

.e-signup {
  overflow-y: scroll;
}

/*user profile*/
.e-user-head .css-1hwfws3 {
  padding: 8px 108px;
}

.e-user-head .css-tlfecz-indicatorContainer {
  /*background: */
}

.e-user-pro-sec,
.e-partner-pro-sec {
  background-color: #F7FBFF;
  padding: 100px 0px 40px 0px;
  background-image: url('../../assets/images/partnerProfile/partnerprobg.svg');
  background-repeat: no-repeat;
}

.e-userpro-bg1 {
  position: absolute;
  top: -100px;
  left: -20px;
}

.e-user-name {
  font-weight: 800;
  font-size: 40px;
  line-height: 56px;
  color: #444444;
}

.e-user-dtls {
  font-size: 16px;
  line-height: 21px;
  color: black;
}

.e-userdlts-label {
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
}

.e-user-card {
  background: #FFFFFF;
  box-shadow: 0px 16px 32px rgba(221, 230, 237, 0.54);
  border-radius: 8px;
  padding: 10px 0px;
}

.e-user-card p {
  font-size: 14px;
  line-height: 28px;
  color: black
}

.e-user-card-h {
  font-weight: 800;
  font-size: 37px;
  font-feature-settings: 'pnum' on, 'lnum' on, 'ss03' on, 'ss01' on;
  color: #444444;
}

.e-user-card-h span {
  font-size: 17px;
  font-weight: 800;
}

.e-userpro-bg2 {
  position: absolute;
  top: -30px;
}

.e-usr-tab-sec {
  padding-bottom: 100px;
}

.e-hist-table-wrapp {
  min-height: 40vh;
}

.e-wallet-click {
  background-image: url('../../assets/images/userProfile/Union.svg');
  background-position: 70% 15%;
  background-repeat: no-repeat;
  transition: ease 0.5s;
}

.e-wallet-click:hover {
  cursor: pointer;
  transform: translateY(-5px);
}

/*purchase tab content*/
.e-purchase-card {
  background: #FFFFFF;
  box-shadow: 0px 16px 32px rgba(221, 230, 237, 0.54);
  border-radius: 8px !important;
  padding: 10px 20px;
}

.e-purchase-card-img {
  height: 15.11px;
}

.e-purchase-card h5 {
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  color: #444444;
}

.e-purchase-card p {
  font-size: 12px;
  line-height: 16px;
  color: #444444;
}

.e-purchase-card-data {
  font-weight: 800;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
}

.e-purchase-card .e-primary-blue-btn {
  padding: 11px 20px;
  font-weight: 800;
  font-size: 14px;
  transition: ease-in-out 0.5s;
}

.e-purchase-card .e-primary-blue-btn:hover {
  background: #2756A2;
}

.e-purchase-card-sensi-img {
  height: 32px;
}

.e-modal-loader {
  width: 20px;
}

.e-purchase-revw-btn {
  padding: 12px 25px !important;
  font-size: 15px !important;
}

.e-trail-puchase {
  width: 42%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/*payment tab content*/
.e-payment-card {
  background: #FFFFFF;
  box-shadow: 0px 16px 32px rgba(221, 230, 237, 0.54);
  border-radius: 8px !important;
  padding: 10px 20px 0px 20px;
}

.e-payment-card h5 {
  font-weight: 800;
  font-size: 15px;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  color: #444444;
}

.e-payment-paid {
  font-size: 14px;
  line-height: 18px;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  color: black;
}

.e-payment-paid .e-expire {
  color: red;
}

.e-payment-paid .e-underline {
  color: #2e2e2e;
  text-decoration: underline;
  font-weight: 400;
  transition: all ease .3;
}

.e-payment-paid .e-underline:hover {
  color: #0461D1
}

.e-payment-p {
  font-size: 12px;
  line-height: 16px;
  color: #444444;
}

.e-payment-card b {
  font-weight: 800;
}

/*wallet history*/
.e-wallethist-head {
  background-color: #0461D1;
}

.e-wallethist-head h4 {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  font-feature-settings: 'ss03' on, 'ss01' on;
  color: #FFFFFF;
}

.e-wallethist-head h1 {
  font-weight: 800;
  font-size: 40px;
  line-height: 56px;
  font-feature-settings: 'ss03' on, 'ss01' on;
  color: #FFFFFF;
}

.e-wallethist-table-head {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  font-feature-settings: 'ss04' on, 'ss05' on, 'ss01' on, 'ss03' on;
  color: rgba(68, 68, 68, 0.67);
}

.e-wallethist-table {
  font-size: 14px;
  line-height: 18px;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  color: #444444;
}

.e-wallethist-table>tbody>tr:first-child>th,
.table>tbody>tr:first-child>td {
  border-top: 0;
}

.e-wallethist-table td {
  padding: 20px 0px !important;
}

.e-wallethist-table .e-date,
.e-amount {
  font-weight: 600;

}

.e-wallethist-table .e-info {
  font-weight: 200;
}

/*partner profile*/
.e-partner-pro-sec {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  font-feature-settings: 'ss04' on, 'ss05' on, 'ss01' on, 'ss03' on;

  color: rgba(68, 68, 68, 0.67);

}

.table-striped>tbody>tr:nth-child(2n+1)>td,
.table-striped>tbody>tr:nth-child(2n+1)>th {
  background-color: #fff;
}

.table-striped>tbody>tr:nth-child(2n)>td,
.table-striped>tbody>tr:nth-child(2n)>th {
  background-color: #F6F6F6;
}

.table-striped>thead>tr>th {
  height: 49px;
  vertical-align: middle;
  font-weight: 800;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.01em;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  color: #444444;
}

.table-striped>tbody>tr>td {
  height: 69px;
  vertical-align: middle;
  font-weight: 200;
  font-size: 14px;
  line-height: 18px;
  color: #444444;
  letter-spacing: 0.01em;
}

.e-bankdtls-card {
  background: #FFFFFF;
  box-shadow: 0px 16px 32px rgba(221, 230, 237, 0.62);
  border-radius: 8px !important;
}

.e-bankdtls-edit {
  border: none;
  background-color: #ffffff;
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.05em;
  font-feature-settings: 'ss01' on, 'ss03' on;
  color: #0461D1;
}

.e-bank-no {
  font-weight: 200;
  font-size: 14px;
  line-height: 18px;
  color: #444444;
  margin-block-end: -2px;
}

.e-bank-usrname {
  font-size: 14px;
  line-height: 18px;
}

.e-partnerdlts-label {
  font-weight: 800;
  font-size: 16px;
  line-height: 21px;
  font-feature-settings: 'ss03' on, 'ss01' on;
  color: #444444;
}

/*tabs*/
.e-rating-above {
  position: relative;
  top: 2px;
}

.e-tab {
  border: none;
  background-color: white;
  padding: 20px 0px;
  overflow-x: auto;
  white-space: nowrap;
}


.e-tablinks {
  background-color: white;
  color: rgba(68, 68, 68, 0.67);
  font-weight: 600;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.02em;
  font-feature-settings: 'ss04' on, 'ss05' on, 'ss01' on, 'ss03' on;
  padding: 0;
  display: inline-block;
  float: none;
}

.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}

.e-tabbtn-active {
  color: #0461D1 !important;
  border-bottom: 2px solid #0461D1 !important;
}

.e-rating-star input[type="radio"] {
  display: none;
}

.e-star {
  font-size: 35px;
  padding-right: 10px;
}

.e-star:hover {
  cursor: pointer;
}


.e-pagination {
  list-style: none;
}

.e-pagination li {
  display: inline-block;
  margin: 0 8px;
  /*padding: 3px 10px;*/
  cursor: pointer;
  width: 33px;
  height: 33px;
  text-align: center;
}

.e-pagination button:disabled {
  opacity: .4;
}

.e-pagination .e-previous-page,
.e-pagination .e-next-page {
  padding: 0;
  margin: 0 !important;
}

.e-pagin-prev,
.e-pagin-next {
  border: none;
  background-color: #ffffff;
}

.e-pagin-number {
  border: none;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  color: #444444;

}

.e-pagin-active {
  background: #F0F0F0;
  border: 1px solid #0461D1 !important;
  padding: 3px 9px;
  border-radius: 50%;
}

.e-pagin-active a {
  background-color: #F0F0F0;
}

.input-range__track--active,
.input-range__slider {
  background-color: #0461D1 !important;

}

.input-range {
  width: 100%;
  margin: 30px 0px;
}

.input-range__slider {
  height: 12px;
  width: 12px;
  margin-top: -8px;
}

.input-range__label-container:before {
  content: "\20B9";
  padding-right: 2px;
}

.input-range__track--active,
.input-range__track--background {
  height: 2px;
}

.input-range__track--background {
  background-color: #D5D5D5 !important;
  left: 8px !important;
}

.input-range__label--max,
.input-range__label--min {
  display: none;
}

.input-range__label--value {
  top: 10px;
  color: #444444;
}

.PrivateSwitchBase-root-1 {
  /*padding: 5px 9px !important;*/
}

.MuiIconButton-colorSecondary:hover {
  background-color: transparent !important;
}


/* Epmty Screen */
.e-empty-screen {
  height: 100vh;
  font-weight: 100vw;
}



/*Loaders*/
.e-page-loader {
  width: 24px;
}

.e-page-overlay-loader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgb(255 255 255 / 0.8);
  left: 0;
  top: 0;
  z-index: 99;
  text-align: center;
  padding-top: 250px;
}

.e-page-overlay-loader .e-page-loader {
  width: 200px;
}

.e-min-height-loader {
  min-height: 90vh;
}

/*Nothing found */
.e-nothing-found {
  width: 150px;
}

.e-nothing-found-txt {
  font-size: 14px;
  font-weight: 600;
}


/*Rating star*/
.e-full-star,
.e-black-star,
.e-half-star {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 15px;
  position: relative;
  top: 5px;
}

.e-full-star {
  background-image: url("../../assets/images/rating/full-star.svg");
}

.e-black-star {
  background-image: url("../../assets/images/rating/rating.svg");
  width: 22px;
  height: 22px;
}

.e-half-star {
  background-image: url("../../assets/images/rating/half-star.svg");
}

.e-black-star.e-white-star {
  background-image: url("../../assets/images/rating/white-star.svg");
}

.e-card-star .e-full-star,
.e-card-star .e-black-star,
.e-card-star .e-half-star {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.e-review-s .e-full-star,
.e-review-s .e-black-star,
.e-review-s .e-half-star {
  width: 10px;
  height: 10px;
  margin-right: 5px;
  top: -3px;
}

.e-review-s .e-black-star {
  width: 9px;
  height: 9px;
}

/*Safari */
@media not all and (min-resolution:.001dpcm) {
  @media {
    .e-marketplace-search {
      padding-top: 14px;
    }

  }
}



/*Chat widget*/
.e-chat-widget-wrap {
  position: fixed;
  background: #fff;
  width: 380px;
  bottom: 30px;
  right: 30px;
  z-index: 9999;
  border-radius: 15px;
  box-shadow: 0px 6px 44.1px 4.9px rgba(177, 177, 177, 0.16);
  background-color: #ffffff;
}

.e-chat-head-wrap h6 {
  color: #ffffff;
  font-size: 13px;
  font-weight: 600;
}

.e-chat-close .close {
  position: absolute;
  right: 15px;
  top: 2px;
  color: #fff;
  font-weight: 100;
  font-size: 30px;
}

.e-chat-input-wrap {
  position: fixed;
  bottom: 30px;
  padding-bottom: 20px;
  background: #fff;
  display: block;
}

.e-chat-input {
  width: 300px;
  border-radius: 15px;
  background-color: #ececec;
  border: 0;
  padding: 10px 15px;
  font-size: 14px;
  margin-left: 20px;
  min-height: 40px;
  max-height: 80px
}

.e-chat-send {
  width: 20px;
  height: 20px;
  background-image: url("../images/send-button-blue.svg");
  display: inline-block;
  background-size: cover;
  margin-top: 7px;
}

.e-chat-back {
  background: #f9f9f9;
}

.e-chat-back span {
  font-size: 12px;
  text-decoration: underline;
  font-weight: 600;
  padding: 15px;
  display: inline-block;
  background: #f9f9f9;
  transition: all .3s linear;
  cursor: pointer;
  background-image: url("../images/back.svg");
  background-size: 12px;
  background-position: 10px;
  padding-left: 30px;
  background-repeat: no-repeat;
}

.e-chat-back span:hover {
  color: #3399cc;
  background-image: url("../images/back-blue.svg");
}

.e-conversation-wrap {
  border: solid 1px #ececec;
  background-color: #ffffff;
  height: 55vh;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  position: sticky;
  border-radius: 0 0 10px 10px;
}

.e-conversation-head {
  background-color: #f9f9f9;
  padding: 5px 15px;
  border-bottom: 1px solid #ececec;
}

.e-conversation-head h6 {
  font-size: 16px;
}

.e-conversation-item {
  padding: 15px;
  border-bottom: 1px solid #ececec;
  transition: all .3s linear;
  cursor: pointer;
}

.e-conversation-item .e-request-placeholder {
  width: 30px;
  height: 30px;
  font-size: 14px;
  padding-top: 4px;
}

.e-conversation-item .media-body h5 {
  font-size: 14px;
  margin-bottom: 0px !important;
  font-weight: 500;
}

.e-conversation-item .media-body p {
  margin-bottom: 0 !important;
  color: #8c8a8a;
  font-size: 10px;
  font-weight: 500;
}

.e-conversation-item.e-active,
.e-conversation-item:hover {
  background-color: #e9e9e9;
}

.e-unread-dot {
  width: 10px;
  height: 10px;
  background: #709fde;
  border-radius: 50%;
  position: relative;
  top: 15px;
  display: none;
}

.e-conversation-item.e-unseen .e-unread-dot {
  display: inline-block;
}

.e-chat-placeholder-wrap {
  min-height: 95px !important;
}

.e-chat-animated-bg {
  height: 65px !important;
}

.e-chat-wrap {
  margin-bottom: 10px;
  overflow: hidden;
}

.e-chat-head-wrap {
  padding: 12px 15px;
  background: #0a5ecd;
  box-shadow: 0px 6px 44.1px 4.9px rgba(177, 177, 177, 0.16);
  border-radius: 15px 15px 0 0;
}

.e-chat-head-wrap.e-general-head {
  background: #3399cc;
  padding: 18px 15px 18px;
}

.e-chat-head-wrap h6 {
  font-size: 15px;
}

.e-chat-head-wrap .e-chat-head {
  color: #9e9e9e;
  font-size: 14px;
  font-weight: 500;
}

.e-chat-head-wrap .e-chat-head b {
  color: #000000;
}

.e-chat-more-icon {
  /*background-image: url("../images/more-icon.svg");*/
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  background-size: cover;
  cursor: pointer;
}

.e-chat-more-dropdown {
  box-shadow: 0px 7.5px 11.7px 1.8px rgba(169, 162, 162, 0.14);
  background-color: #ffffff;
  border-radius: 10px;
  padding: 15px;
  position: absolute;
  right: 40px;
  top: 55px;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
}

.e-chat-more-icon.e-close {
  /*background-image: url("../images/close-icon.svg");*/
}

.e-chat-body {
  padding: 10px 10px 30px;
  height: 50vh;
  overflow-y: scroll;
  display: block;
  overflow-x: hidden;
}

.e-chat-container {
  padding: 10px 10px 30px;
  height: 50vh;
  overflow-y: scroll;
  display: block;
  overflow-x: hidden;
  margin-bottom: 50px;
}

.e-chat-container .e-user-avatar,
.e-chat-container .e-admin-avatar {
  max-height: 24px;
  border-radius: 50%;
  margin-left: 5px;
  position: relative;
}

.e-chat-container .e-admin-avatar {
  max-height: 30px;
  margin-right: 10px;
  margin-left: 0;
}

.e-chat-body-inner {
  padding-bottom: 30px;
}

.e-chat-item {
  position: relative;
  margin-bottom: 5px;
}

.e-chat-pic {
  width: 30px;
  height: 30px;
  background-image: linear-gradient(to top, #3c80da, #0065d2);
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  padding-top: 5px;
  position: relative;
  top: -8px;
  margin-left: 10px;
  opacity: 0;
  text-transform: uppercase;
}

img.e-chat-pic {
  background: none;
  object-fit: cover;
  padding: 0;
  border: 1px solid #ccc;
  box-shadow: 0px 5px 15px 0 rgba(197, 190, 190, 0.1);
  top: 0;
  position: relative;
}

.e-chat-message {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  border-radius: 10px;
  background-color: #e7e7e7;
  display: inline-block;
  padding: 10px 15px;
  max-width: 80%;
  word-wrap: break-word;
}

.e-chat-user .e-chat-message {
  color: #fff;
  background-color: #0b5ecd;
}

.e-general-chat-body .e-chat-user .e-chat-message {
  background-color: #3399cc;
}

.e-chat-admin .e-chat-pic {
  top: 6px;
  margin-left: 0;
  margin-right: 10px;
  background-image: url("../images/support-avatar.svg");
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: center;
}

.e-chat-user+.e-chat-admin .e-chat-pic,
.e-chat-admin+.e-chat-user .e-chat-pic,
.e-chat-admin:first-child .e-chat-pic,
.e-chat-user:first-child .e-chat-pic {
  opacity: 1;
}

.e-chat-admin+.e-chat-user {
  margin-top: 20px;
}

.e-chat-user+.e-chat-admin {
  margin-top: 20px;
}

.e-chat-footer {
  padding: 15px 30px 30px;
}

.e-chat-footer input {
  width: 100%;
  border-radius: 10px;
  background-color: #e7e7e7;
  padding: 15px;
  border: none;
  outline: 0;
}

.e-chat-time {
  font-size: 10px;
  color: #2e2e2e;
  display: block;
  padding-top: 5px;
}

.e-chat-user .e-chat-time {
  position: absolute;
  bottom: -20px;
  right: 45px;

}

.e-chat-admin .e-chat-time {
  margin-left: 45px;
  padding-bottom: 15px;
}

.e-closed-txt {
  font-size: 14px;
  font-weight: 600;
  background: #f9f9f9;
  text-align: center;
  padding: 15px;
  position: fixed;
  bottom: 50px;
  display: block;
  width: 303px;
  margin: 0 auto;
  border-radius: 5px;
}

.e-chat-loading {
  min-height: 50vh;
  background-image: url("../images/lazyload.gif");
  background-size: 100px;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
}

.e-new-chat-txt {
  font-size: 12px;
  font-weight: 600;
  color: #000;
  cursor: pointer;
  background-image: url("../images/plus.svg");
  background-size: 8px;
  background-repeat: no-repeat;
  background-position: 0 5px;
  padding-left: 15px;
  transition: all .3s linear;
  position: absolute;
  right: 15px;
  top: 14px;
}

.e-new-chat-txt:hover {
  color: #3399cc;
  background-image: url("../images/plus-blue.svg");
}

.e-conversation-select .css-yk16xz-control {
  min-width: 120px !important;
  background: transparent;
  border: 0;
  min-height: 13px !important;
}

.e-conversation-select .css-1pahdxg-control {
  min-width: 120px !important;
  background: transparent;
  border: 0;
  min-height: 13px !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.e-conversation-select .css-1okebmr-indicatorSeparator {
  display: none;
}

.e-conversation-select .css-1uccc91-singleValue {
  font-size: 12px;
  font-weight: 600;
  color: #000;
  padding-left: 0 !important;
  margin-left: 0;
}

.e-conversation-select .css-1l6guga-control,
.e-conversation-select .css-peowp8-control {
  width: 160px !important;
  height: 30px;
}

.e-conversation-select .css-tlfecz-indicatorContainer {
  padding: 4px !important;
}

.e-conversation-select .css-1hwfws3 {
  padding-left: 0 !important;
}

.e-chat-more-icon {
  width: 12px;
  height: 12px;
  background-image: url("../images/more.svg");
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  position: absolute;
  top: 68px;
  right: 15px;
  cursor: pointer;
  padding: 10px;
}

.e-chat-more-icon:hover {
  background-image: url("../images/more-blue.svg");
}

.e-chat-more-wrap {
  padding: 6px 15px;
  background-color: #fff;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 7.5px 11.7px 1.8px rgba(169, 162, 162, 0.14);
  display: inline-block;
  position: absolute;
  top: 90px;
  right: 15px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 12px;
  z-index: 99;
  transition: all .3s linear;
}

.e-chat-more-wrap:hover {
  color: #3399cc;
}

.e-conversation-found img {
  width: 150px;
}

.e-conversation-found .e-noData-txt {
  font-size: 14px;
  font-weight: 600;
}

.e-conversation-found .e-no-result-wrap {
  padding: 30px;
}

.e-chat-wrap .e-chat-body {
  min-height: 30vh;
}

.modal .modal-footer .text-success {
  font-weight: 600;
}

.e-callback-wrap {
  height: 80vh;
  width: 100vw;
  text-align: center;
}

/*terms and condition*/

.e-terms-head {
  font-weight: 800;
  font-size: 20px;
  line-height: 56px;
  font-feature-settings: 'ss03' on, 'ss01' on;
  color: #444444;
}

/*toast*/
.e-error {
  background-color: #F5C0C0;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 13px;
  color: #D40000;
  font-weight: 500;
  border: 1px solid #D40000;
  text-transform: capitalize;
}

.e-toast {
  position: fixed;
  bottom: 20px;
  right: 30px;
  width: 250px;
  padding: 15px 15px 15px 40px;
  background-color: #fff;
  border: 1px solid #F1F1F1;
  border-radius: 5px;
  box-shadow: 3px 4px 6px 1px rgba(0, 0, 0, 0.06);
  font-size: 14px;
  background-size: 18px;
  background-position: 15px;
  background-repeat: no-repeat;
  z-index: 99;
}

.e-toast.success {
  background-image: url("../images/check.svg");
  border-color: #68D868;
  background-color: #E9FFE0;
}

.e-toast.fail {
  background-image: url("../images/cancel.svg");
  border-color: red;
  background-color: #F9E5E5;
}

/*home*/
.e-home-slider-sec .e-home h5.e-home-h5 span {
  font-weight: 800;
}

.e-home-slider-sec .e-home h5.e-home-h5 {
  /* font-size: 24px; */
  font-size: 14px;
}


/*notification pop up*/
.e-notification-popup {
  background-image: url('../images/Frame232.svg');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 136%;
  width: 445px;
  /*height: 178px;*/
  position: fixed !important;
  bottom: 0;
  right: 0;
  z-index: 999;
  box-shadow: 8px 16px 32px rgba(185, 185, 185, 0.54);
}

.e-popup-close {
  padding: 2px 9px;
  position: absolute;
  right: 21px;
  top: -12px;
  border-radius: 100%;
  box-shadow: 8px 16px 32px rgba(185, 185, 185, 0.54);
}

.e-popup-close .e-toggle {
  width: 11px !important;
}

.e-popup-img {
  width: 100%;
}

.e-notification-popup h5 {
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  color: #000000;
}

.e-notification-popup p {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  color: #444444
}

.e-popup-link {
  background: #13D726;
  border-radius: 4px;
  padding: 10px;
  font-size: 12px;
  line-height: 16px;
  font-feature-settings: 'ss01' on, 'ss03' on;
  color: #FFFFFF;
  transition: ease-in-out 0.5s;
}

.e-popup-link:hover {
  color: #FFFFFF;
  text-decoration: none;
  transform: translateY(-3px);
}


/* Changes */
.e-text-wrap {
  position: relative;
  /* background-color: #ececec; */
  border-radius: 40px;
  /* padding: 20px; */
  /* margin-top: 80px; */
}

.e-p-text {
  /* font-size: 40px;
  color: #0C3E90;
  font-weight: 700; */
  font-weight: 800;
  font-size: 40px;
  line-height: 56px;
  color: #444444;
  /* text-align: center; */
  position: initial;
}

.e-note-text-p {
  color: #000;
  font-weight: 400;
}

.e-note-text-p a {
  text-decoration: underline;
}