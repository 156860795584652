@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:    "Trade Store | Alice Blue"
Version:    1.0
Created:    ""
Use:   "Trading App"
-------------------------------------------------------------------*/


/*------------------------------------------------------------------
[Table of contents]

1. Import Fonts
2. Colors
3. Page loader / .page-loader
4. Global styles
5. Buttons and CTA



-------------------------------------------------------------------*/


/*------------------------------------------------------------------

 - Fonts import, Typography

-------------------------------------------------------------------*/
@font-face{
  font-family: "HK Grotesk";
  src: url("../fonts/HKGrotesk-Black.otf");
  font-weight: 900;
}
@font-face{
  font-family: "HK Grotesk";
  src: url("../fonts/HKGrotesk-ExtraBold.otf");
  font-weight: 800;
}
@font-face{
  font-family: "HK Grotesk";
  src: url("../fonts/HKGrotesk-Bold.otf");
  font-weight: 700;
}
@font-face{
  font-family: "HK Grotesk";
  src: url("../fonts/HKGrotesk-SemiBold.otf");
  font-weight: 600;
}
@font-face{
  font-family: "HK Grotesk";
  src: url("../fonts/HKGrotesk-MediumLegacy.otf");
  font-weight: 500;
}
@font-face{
  font-family: "HK Grotesk";
  src: url("../fonts/HKGrotesk-Light.otf");
  font-weight: 200;
}

/*------------------------------------------------------------------

 - Globel Style

-------------------------------------------------------------------*/
*,*:focus,*:active{
  border: none ;
  outline: none !important;
}
html body{
	font-family: HK Grotesk;
  scroll-behavior: smooth !important;
  overflow-x: hidden;
}
h2{
	font-family: HK Grotesk;
	font-weight: 800 !important;
	font-feature-settings: 'ss03' on, 'ss01' on;

}
p{
	font-family: HK Grotesk;
	font-weight: 200;
	font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
}
.e-primary-grn-btn{
	border-radius: 4px;
	padding: 10px 20px;
	background-color: #13D726;
	color: white;
}
.e-primary-blue-btn{
  border-radius: 4px;
  background: #0461D1;
  color: white;
}
.e-primary-blue-btn:hover{
  background-color: #2756A2;
}
.e-primary-blue-btn[disabled]{
  opacity: 0.6;
  cursor: not-allowed;
}
.e-primary-blue-btn[disabled]:hover{
  background: #0461D1;
}
.e-primary-otln-btn{
  border-radius: 4px;
  padding: 10px 20px;
}
.react-tabs__tab{
  display: inline-block;
  border: none;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  color: rgba(68, 68, 68, 0.67);
  font-size: 14px;
  font-weight: 800;
  line-height: 18px;
  letter-spacing: 0.02em;
  font-feature-settings: 'ss04' on, 'ss05' on, 'ss01' on, 'ss03' on;
}
.react-tabs__tab-list{
  border: none;
  margin-left: -15px;
}
.react-tabs__tab--selected{
  color: #0461D1;
}
.react-tabs__tab--selected:before{
  color: #0461D1;
  content: "";
  position: absolute;
  width: 78%;
  height: 2px;
  top: 25px;
  left: 13px;
  background-color: #0461D1;
  visibility: visible;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
}
#react-tabs-2:before{
  width: 65%;
}

 #react-tabs-3:before{
  width: 100%;
}
#react-tabs-4:before{
  width: 70%;
}
.e-video-modal{
  top: 100px !important;
  left: 300px !important;
  display: block !important;
  width: 690.84px !important;
  height: 396px !important;
}
.e-review-modal{
  top: 80px !important;
  left: 400px !important;
  display: none;
  background-color: #ffffff;
  background: #FFFFFF;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  width: 445px !important;
  height: 460px !important;
}
.e-modal-show{
  display: block !important;
}
.e-read-more{
  font-weight: 200;
  font-size: 14px;
  line-height: 24px;
  color: #0461D1;
  transition: ease-in-out 0.5s;
  display: block;
  margin-top: -15px;
}
.e-readmore-p{
  height: auto;
}