@charset "utf-8";
/* ==============================
   Small desktop
   ============================== */

@media only screen and (max-width: 1366px) {
  /*resource video details page*/
   .e-videdtls .e-vidoelist-play-btn, .e-webinar-dtls .e-vidoelist-play-btn{
    left: 50%;
    top: 50%;
  }
  .e-webinar-time{
    left: 0;
    top: 60%;
  }
  .e-blog-content-right{
      left: 1020px !important;
  }
}

@media only screen and (max-width: 1200px) {


}


@media only screen and (max-width: 1199px) {

    .e-blog-content-right{
      left: 970px !important;
      }
   .e-playstorebanner-img{
     right: -50px;
   }

   .e-playstorelink-btn, .e-appstorelink-btn{
      margin-bottom: 50px;
   }
   .e-fiftypluscol-bg{
      right: -20px;
   }
     .e-home-explore-prdt-sec .e-primary-blue-btn, .e-home-explore-prdt-sec .e-primary-otln-btn{
      padding: 8px 6px;
      margin-top: 10px;
   }
   .e-marketplace-allcard-sec .e-primary-blue-btn, .e-marketplace-allcard-sec .e-primary-otln-btn{
      padding: 8px 6px;
   }
   .e-home-serive-sec{
      padding-bottom: 200px;
      position: relative;
      background-image: url("./images/homebgwave.svg"), url("./images/homebgwave1.svg");
      background-position: 40% 90%, 40% 105%;
      background-repeat: no-repeat;
      background-size: 1000px 300px;
   }
   .e-home-explore-prdt-sec{
      padding-top: 20px;
   }
   /*welcome trade store banner*/
   .e-welcomebanner{
      background-size: 950px;
      padding-bottom: 45px;
   }
   /*marketplace details*/
   .e-play-btn{
      left: 250px;
   }
   .e-prdtld-ext{
      padding: 15px 17px;
   }
   .e-prdtdtls-card .e-primary-blue-btn, .e-prdtdtls-card .e-primary-grn-btn{
      width: 138px;
      padding: 15px 6px;
   }
   .e-marketDetails-card-sec{
      padding-bottom: 50px !important;
   }
   .e-mrktplc-playbanner-sec{
      padding-bottom: 100px;
   }
   .e-bookmark-icn{
      margin-left: 10px;
   }
   .e-img-thum{
      width: 130px;
      height: 100px;
   }
   .e-refer-card .e-text{
      padding-right: 20px;
   }
   .e-input-wrap{
      /*width: 213px;*/
   }
   .e-refer-card .e-primary-blue-btn{
      width: 111px;
      padding: 5px 17px;
   }
   .e-smallcase-bnft .e-form-rule{
       top : 1170px;
   }
   .e-productdetls-about-smallcase .e-form-rule{
       top : 580px;
   }
   .e-support-img {
      height: 80px;
   }
   .e-marketDetails-card .e-primary-blue-btn, .e-marketDetails-card .e-primary-otln-btn{
      padding: 8px 6px;
      margin-top: 10px;
   }
    .e-already-purchased {
      margin-top: 8px;
   }
   .e-marketDetails-card .e-primary-blue-btn {

    font-size: 12px;
   }
   .e-card-btn-wrap button {
    padding: 8px 0 !important;
 }
   .e-text{
      width: 100%;
   }
   /*login*/
   .e-login .e-primary-blue-btn{
      padding: 19px 40px ;
      width: 100%;
   }
   .e-login .e-primary-otln-btn{
      padding: 19px 40px ;
      width: 100%;
   }
   /*review tab*/
   .e-review-card-star{
      margin-left: 10px;
   }
   /*faq tab*/
   .e-ac-open-card  .e-card-explore{
      font-size: 10px;
   }
   /*user profile purchase card*/
   .e-purchase-card .e-primary-blue-btn{
      padding: 11px 15px;
   }
   .e-refer-card .e-primary-blue-btn{
      padding: 13px 17px !important;
   }
    /*resource video details page*/

}

/* ==============================
   Tablet landskape
   ============================== */
@media only screen and (max-width: 1024px) {

   html, body {
      overflow-x: hidden;
   }

   .e-getstartbannerbg{
      right: 250px;
   }
   .e-home-product-card{
      padding: 70px 40px;
   }

   .e-fiftypluscol-bg{
      bottom: 15px;
   }
   .e-joinaliceblue-btn{
      z-index: 2;
      position: relative;
   }
   .e-fooet-sec{
      padding: 40px ;
   }
   .e-video-details-main-sec{
      padding-bottom: 100px;
   }
   .e-blog-content-right{
      left: 850px !important;
   }
   .e-marketDetailsReview-sec{
      padding: 10px 0 100px;
   }

}

@media only screen and (max-width: 992px) {

   .navbar-nav{
      width: 100%;
   }
    .e-usr-select{
      display: none;
   }
   .e-usr-dropdown{
      display: block;
   }
   .e-usr-nav-link{
   color: #444444;
   font-size: 14px;
   line-height: 18px;
   font-feature-settings: 'ss01' on, 'ss03' on;
   font-family: HK Grotesk;
   font-weight: 600;
}

    .e-playstorebanner-img, .e-playstorebannerbg-orng, .e-playstorebannerbg-blue, .e-playstorebannerbg-green{
      display: none;
   }
   .e-home-product-card{
      padding: 70px 80px;
   }
   .e-home-explore-prdt-sec .e-marketDetails-card{
      margin-top: 20px;
   }
   .e-prdt-col-bg{
      left: 250px;
   }
   .e-fiftypluscol-bg{
      right: 175px;
   }
   .e-home{
      background-image: none;
      /* background-repeat: no-repeat;
      background-position: 15% 75% ; */
   }
   .e-home-serive-sec{
      background-position: 40% 90%, 40% 95%;
   }
   .e-home-card-sec{
      padding: 20px 0px 100px 0px ;
   }
   .e-home-prdt-section{
      padding-bottom: 30px;
   }
   .e-home-explore-prdt-sec{
      padding-bottom: 80px;
   }
   .e-head-logo-link{
      margin-left: -4px;
   }
   .e-fixed{
      position: fixed !important;
      z-index: 999;
      width: 90%;
      left: 30px;
   }
   .navbar-toggler{
      z-index: 9999;
   }
.e-nav-item{
      box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.08);
      top: 0px;
      padding-top: 50px;
      background-color: white;
      z-index: 999;
   }
   .e-footer-email-input{
      padding: 8px 2px 9px 0px;
   }
   .e-footer-subs-btn{
      padding: 12px 8px 12px 7px;
   }
   .e-footer-email-input::placeholder{
      padding-left: 10px;
   }
   .e-login, .e-signup, .e-bnk-dtls-popup, .e-lead{
      width: 60% !important;
      right: -600vW;
   }
   .e-login-fly{
      right: 0;
   }
   /*market place*/
   .e-markt-filter-row{
      display: none !important;
   }
   .e-filter-btn{
      display: block;
   }
   .e-mrktplc-close{
      display: block;
   }
   .e-market-fil{
     right: -500vW;
     width: 60vW !important;
     height: 100%;
     overflow: hidden;
     position: fixed;
     background-color: #ffffff;
     transition: ease-in-out 1s;
     z-index: 10;
     top: 0;
     overflow-y: scroll;
     padding: 30px;
   }
   .input-range{
      width: 100%;
   }
   .e-market-fil .e-login-closebtn{
      margin-left: -20px;
   }
    .e-prdtld-ext{
      padding: 15px 31px;
   }

   /*welcome trade store banner*/
   .e-welcomebanner{
      background-size: 850px;
      background-position: 40% 100%;
      padding-bottom: 20px;
   }
   .e-welcomebanner h2{
      font-size: 35px;
   }
   /*marketplace details*/
   .e-marketplace-card-sec{
      padding-bottom: 50px;
   }
   .e-marketplace-playbanner-sec{
      padding-bottom: 100px;
   }
   .e-smallcase-banner-img{
      margin-right: 0;
   }
   .e-play-btn{
      left: 350px;
   }
   .e-smallcase-bnft .e-form-rule{
       top : 1170px;
   }
   .e-img-thum{
      width: 160px;
      height: 134px;
   }
   .e-support-img {
      height: 120px;
   }
   /*review tab*/
   .e-filter-select{
      width: 200px;
   }
   /*faq tab*/
   .e-faq-gethelp h1{
      padding: 10px 100px;
   }
   .e-faqbg-blue{
      top: 1600px;
      left: 10px;
   }
   .e-faqbg-orng{
      top: 1600px;
      right: 0;
   }
   .e-faqbg-grn{
      top: 1350px;
   }
   /*perfomance report */
   .e-report-query-sec .e-faqbg-blue{
      top: 2000px;

   }
   .e-report-query-sec .e-faqbg-orng{
      top: 1900px;
   }
   .e-report-query-sec .e-faqbg-grn{
      top: 1600px;
      right: 20px;
   }
   /*contact page*/
   .e-contact-card {
      margin-top: 50px;
   }
   /*user profile purchase card*/
   .e-purchase-card{
      margin-top: 20px;
   }
    .e-purchase-card .e-primary-blue-btn{
      padding: 11px 20px;
   }
   .e-already-purchased{
      margin-top: 10px;
   }
   .e-videodetails-card{
     padding: 100px 29px 30px 18px;
   }

   .e-blog-content-fixed{
  position: relative !important;
  top: 0;
}
.e-blog-content-right{
  position: relative !important;
  top: 0;
  left: 0 !important;
}
.e-contact-playbanner-sec{
   padding: 100px 0px ;
}
   /*resource video details*/
  /* .e-resource-video-cover, .e-resource-video, .e-video-detail-play:after{
      width: 600px;
   }
   .e-video-detail-play:after{
      left: 15px;
   }*/

}

/* ==============================
   Tablet Portrait
   ============================== */
@media only screen and (max-width: 768px) {

   .slick-slide .slick-cloned{
      width: 50%;
   }
   .e-home{
      background-image: url("./images/homebg.png");
      background-repeat: no-repeat;
      background-position: 15% 75% ;
   }
   .e-bgimagesignal{
      left: 0;
   }
   .e-bgimagewired{
      left: 90px;
   }
   .e-bgimagepercentage{
      right: 0;
   }
   .e-bgimagecell{
      right: 50px;
   }


/*------------------------------------------------------------------

 -Playstore store banner

-------------------------------------------------------------------*/
   .e-playstorebannerbg-green{
      right: 150px;
   }
    .e-playstorebannerbg-blue{
      bottom: 20px;
      right: 500px;
   }
    .e-playstorelink-btn{
      /*margin-left: 20px;*/
   }
   .e-playstorebannerbg-orng{
      left: 50px;
      top: 400px;

   }
   /*mwelcome trade store banner*/
   .e-welcomebanner{
      background-position: 20% 0%;
      padding-bottom: 0;
   }
   .e-welcomebanner h2{
      margin-top: 8px !important;
   }

/*------------------------------------------------------------------

 -Get start banner

-------------------------------------------------------------------*/

   .e-getstartbannerbg-grn{
      width: 150px;
   }
   .e-getstartbannerbg{
      width: 70px;
      right: 200px;
   }
   .e-getstartbannerbg-org{
      right: 200px;
   }


   .e-home{
      background-image: url("./images/homebg.png");
      background-repeat: no-repeat;
      background-position: -5% 80% ;
      padding-top: 40px;
   }

   .e-h-product-card-right{
      right: 0;
      top: 100px;
   }

   .e-playstore-banner{
      padding: 24px 0px 0px 24px !important ;
   }
   .e-home-form{
      margin-top: 50px;
   }
   .e-home-slider-sec .e-form-rule{
      top : 30px;
   }
   .e-home-downarrow{
      display: none;
   }
   .e-home-plystore{
      padding: 30px !important;
   }
   .e-prdt-col-bg{
      left: 180px;
   }
   .e-home-product-card-sm{
      width: 150px;
   }
   .e-home-product-card{
      padding: 70px 50px;
   }

   .e-fiftypluscol-bg{
      right: 68px;
   }
   .e-join-ab-sec .e-video-modal{
      left: 100px !important;
      width: 550px !important;
      top: 30px !important;
   }
   .e-home-explore-prdt-sec .e-primary-blue-btn{
      padding: 8px 15px;
   }
   .e-home-explore-prdt-sec .e-primary-otln-btn{
      padding: 8px 15px;
   }

   .e-home-product-sec{
      margin-top: -100px;
   }
   .e-home-prdt-bgimg{
      top: -100px;
      left: 450px;
   }
   .e-home-prdt-bgimg1{
      top: -70px;
      left: 260px;
   }
   .e-home-pr-card-col{
      background-image: url("./images/hometradebg3.png");
      background-repeat: no-repeat;
      background-position: 10% 60%;
      margin-top: 20px;
   }
    .e-home-product-sec{
      margin-top: -200px;
   }
   .e-home-card{
      margin-top: 20px;
   }
   .e-algo-card{
      margin-top: 20px !important;
   }
   .e-img{
      width: 100%;
   }
   .e-fooet-sec{
      padding: 40px ;
   }
   .e-footer-email-input{
      padding: 8px 28px 8px 15px;
   }
   .e-footer-subs-btn{
      padding: 12px 22px 12px 22px;
   }
   .e-footer-email-input::placeholder{
      padding-left: 0;
   }
   .e-ftr-subsription-sec{
      margin-top: 20px;
   }



   /*market place details*/
   .e-smallcase-banner-img{
      display: none;
   }
   .e-play-btn{
      left: 250px;
   }
   .e-img-thum{
      width: 120px;
      height: 100px;
   }
   .e-productdetls-about-smallcase .e-form-rule{
       top : 580px;
   }
   .e-smallcase-bnft .e-form-rule{
       top : 1190px;
   }
    .e-marketDetails-card{
      margin-top: 20px;
   }
   /*review tab*/
   .e-review-card-star{
      margin-left: 160px;
   }
   /*faq  tab*/
   .e-faq-gethelp h1{
      padding: 10px 30px;
   }
   .e-ac-open-card  .e-card-explore{
      font-size: 14px;
   }
   .e-faqbg-grn{
      right: 10px;
   }
   /*resource video details*/
  /* .e-resource-video-cover, .e-resource-video, .e-video-detail-play:after{
    width: 500px;
   }
   .e-video-detail-play:after{
      left: 0;
   }*/
   .e-webinar-time{
    
    top: 70%;
  }
  /*resource blog details*/

}

/* ==============================
   Phone
   ============================== */
@media only screen and (max-width: 575px) {


   .slick-slide .slick-cloned{
      width: 100%;
   }
/*------------------------------------------------------------------

 -Playstore store banner

-------------------------------------------------------------------*/

   .e-playstorebannerbg-bluelg{
      width: 200px;
   }
   .e-playstorebannerbg-red{
      width: 100px;
   }
  /*mwelcome trade store banner*/
   .e-welcomebanner{
      background-size: 550px;
      background-position: 20% 0%;
      padding-bottom: 0;
   }
   .e-welcomebanner h2{
      font-size: 20px;
      margin-top: 4px !important;
   }
   .e-welcomebanner p{
      font-size: 12px;
      margin-top: -15px !important;
   }
 /*------------------------------------------------------------------

 -Get start banner

-------------------------------------------------------------------*/
   .e-getstartbannerbg-grn{
      width: 120px;
      right: 0;

   }
   .e-getstartbannerbg{
      width: 50px;
      right: 120px;
   }
   .e-getstartbannerbg-org{
      width: 30px;
      right: 120px;
      bottom: 30px;
   }

   .e-joinaliceblue-content{
      margin-left: 0;
   }


   .e-joinaliceblue-banner{
      padding-bottom: 50px;
   }
   .e-home{
      background-image: none;
      /* background-repeat: no-repeat;
      background-position: 40% 90% ;
      padding-top: 40px; */
   }


   .e-fooet-sec{
      padding: 40px 20px;
   }
   .e-home-slider-sec .e-form-rule{
      top : 50px;
   }
   .e-algo-card{
      margin-top: 20px;
   }
   .e-home-serive-sec{
      padding-top: 0;
   }
   .e-media-card{
      position: relative;
   }
   .media-body{
      padding: 20px 0px 20px 0px;
   }
   .e-blue-card{
      padding: 25px 0px;
   }
   .e-home-media-head{
      font-size: 20px;
   }
   .e-why-trade-card-img{
      position: absolute;
      right: 10px;
      bottom: 20px;
   }
   .e-why-trade-card-img-blue{
      position: absolute;
      right: 0;
      bottom: 10px;
   }
   .e-home-plystore{
      margin-top: 100px;

   }
   .e-join-ab-sec .e-video-modal{
      left: 10px !important;
      width: 390px !important;
   }
   .e-home-vedio-button{
      margin-bottom: 20px;
   }

   .e-prdt-col-bg{
      left: 100px;
   }
   .e-home-prdt-section .e-form-rule{
      top: -10px;
   }
   .e-home-explore-prdt-sec{
      padding-top: 0 !important;
   }
    .e-home-product-sec{
      margin-top: 50px;
   }
   .e-home-pr-card-col{
      padding-top: 50px;
   }
   .e-home-product-desc{
      margin-top: -100px;
   }
   .e-home-pr-card-col{
      background-image: url("./images/hometradebg3.png");
      background-repeat: no-repeat;
      background-position: 60% 50%;
      background-size: 380px 300px;
      padding: 30px !important;
   }
   .e-home-prdt-bgimg{
      top: -100px;
      left: 350px;
      width: 40px;
   }
   .e-home-prdt-bgimg1{
      top: -70px;
      left: 230px;
      width: 30px;
   }
   .e-home-product-card{
      padding: 70px 25px;
   }
   .e-fiftypluscol-bg{
      bottom: 15px;
      right: 45px;
   }
   .e-login, .e-signup, .e-bnk-dtls-popup, .e-lead{
      width: 100% !important;
      right: -600vW;
   }
   .e-login-fly{
      right: 0;
   }
   /*market place*/
   .e-market-fil{
      width: 100% !important;
   }
   .e-marketDetails-review-img {
      height: 15px;
   }
   .e-brdr-right-span{
      padding-right: 5px;
   }
    .e-play-btn{
      left: 220px;
   }
   .e-img-thum{
      width: 100px;
      height: 100px;
      margin-right: 10px;
   }
   .e-smallcase-bnft .e-form-rule{
       display: none;

   }
   .e-prdtdtls-card .e-primary-blue-btn, .e-prdtdtls-card .e-primary-grn-btn{
      width: 118px;
      padding: 15px 0px;
   }
   .e-refer-card .e-primary-blue-btn{
      width: 90px;
      padding: 0px;
   }
   .e-support-img {
      height: 100px;
   }
   .e-support-link{
      font-size: 12px;
   }
   /*review tab*/
   .e-review-card-star{
      margin-left: 80px;
   }
    /*faq  tab*/
   .e-faq-gethelp h1{
      padding: 10px 0px;
   }
   .e-faqbg-blue, .e-faqbg-grn, .e-faqbg-orng{
      display: none;
   }

   /*partner profile*/
   .e-partner-card{
      margin-top: 20px;
   }
   .table .table-striped{
      overflow-x: scroll !important;
   }

   /*contact page*/
   .e-contactus-img{
      width: 120px;
      margin-top: 20px;
   }
   .e-contact-desk .e-form-rule{
      left: 190px;
   }
   /*resource video details*/
/*   .e-resource-video-cover, .e-resource-video, .e-video-detail-play:after{
    width: 350px;
   }
   .e-video-detail-play:after{
      left: 45px;
   }*/
   /*resource blog details*/

   .e-blog-details-header{
    padding-bottom: 225px;
   }
   .e-refer-card .e-primary-blue-btn{
      padding: 12px 5px !important;
   }
}

@media only screen and (max-width: 380px) {
/*------------------------------------------------------------------

 -Playstore store banner

-------------------------------------------------------------------*/

   .e-playstorebannerbg-bluelg{
      width: 200px;
    }
   .e-playstorebannerbg-red{
      width: 80px;
   }

   .e-playstorebanner-img{
      right: 20px;
   }
   .e-playstorebannerbg-blue{
      left: 25px;
      width: 60px;
   }
   .e-playstorebannerbg-orng{
      top: 430px;
      left: 0;
   }
   .e-playstorebannerbg-green{
      right : 0;
   }
    /*mwelcome trade store banner*/
   .e-welcomebanner{
      background-size: 500px;
      background-position: 20% 0%;
   }
   .e-welcomebanner h2{
      margin-top: -20px !important;
      font-size: 15px;
   }
   .e-welcomebanner p{
      font-size: 12px;
      margin-top: -15px !important;
   }
   /*market place details */
   .e-box-span{
      padding: 3px 0px;
   }
    .e-play-btn{
      left: 180px;
   }
   .e-img-thum{
      width: 80px;
      height: 80px;
   }
    .e-prdtld-ext{
      padding: 15px 17px;
    }
   /*review tab*/

 /*------
/*------------------------------------------------------------------

 -Get start banner

-------------------------------------------------------------------*/


   .e-bgimagesignal, .e-bgimagepercentage, .e-bgimagewired, .e-bgimagecell{
      width: 130px;
   }
   .e-bgimagewired{
      left: 20px;
   }
   .e-bgimagecell{
      right: 0;
   }

   .e-home-product-card{
      padding: 40px 5px;
   }
   .e-h-product-card-right{
      top: 90px;
   }
   .e-home-prdt-bgimg{
      top: -50px;
   }
   .e-home-prdt-bgimg1{
      top: -30px;
   }
   .e-home-product-card-sm{
      width: 130px;
   }
   .e-home-product-card-sm {
      padding: 5px;
   }
   .e-footer-email-input{
      padding: 8px 10px 9px 0px;
   }
   .e-footer-subs-btn{
      padding: 12px 15px 12px 15px;
   }
   .e-footer-email-input::placeholder{
      padding-left: 10px;
   }
   .e-login .e-primary-grn-btn{
      padding: 19px 50px;
   }
   .e-login .e-primary-blue-btn{
      padding: 19px 40px 19px 20px;
   }
   /*market place details*/
   .e-productdetls-about-smallcase .e-form-rule{
       top : 560px;
   }
   .e-bookmark-icn {
      margin-left: 1px;
   }
   .e-prdtdtls-card .e-primary-blue-btn{
      margin-right: 5px !important;
   }
    .e-input-wrap{
      /*width: 170px;*/
   }
   .e-refer-card .e-primary-blue-btn{
      width: 80px;
   }
    .e-support-img {
      height: 90px;
      margin-top: 20px;
   }
   .e-support-link{
      font-size: 10px;
   }
   /*review tab*/
   .e-review-card-star{
      margin-left: 0px !important;
   }
   /*fAQ tab*/
    .e-ac-open-card  .e-card-explore{
      font-size: 12px;
   }
   .e-contactus-img{
      width: 100px;
   }
   .e-contact-desk .e-form-rule{
      left: 175px;
   }
   .e-contactdesk-p-blue{
      font-size: 12px;
   }
   /*user profile puchase card*/
    .e-purchase-card .e-primary-blue-btn{
      padding: 11px 15px;
   }
  /* .e-resource-video-cover, .e-resource-video{
    width: 250px;
   }*/
   .e-webinar-time{
   
    top: 80%;
  }
   .e-blog-details-header{
    padding-bottom: 200px;
   }
   .e-refer-card .e-primary-blue-btn{
      padding: 12px 5px !important;
   }
}


/* ==============================
   Small Phone
   ============================== */
@media only screen and (max-width: 320px) {
/*------------------------------------------------------------------

 -Playstore store banner

-------------------------------------------------------------------*/

   .e-playstorebannerbg-bluelg{
      width: 150px;
    }

    .e-playstorebanner-img{
      right: 50px;
   }
   .e-playstorebannerbg-blue{
      left: 10px;
   }
   .e-playstorebannerbg-orng{
      top: 550px;
   }
/*------------------------------------------------------------------

 -Get start banner

-------------------------------------------------------------------*/

   .e-getstartbannerbg-grn{
      width: 70px;
   }
   .e-getstartbannerbg{
      width: 30px;
      right: 80px;
   }
   .e-getstartbannerbg-org{
      width: 20px;
      right: 60px;
      bottom: 0;
   }
   .e-home-product-card{
      padding: 40px 0;
   }

    .e-h-product-card-right{
      top: 10px;
   }
    .e-input-wrap{
      /*width: 150px;*/
   }
   .e-refer-card .e-primary-blue-btn{
      width: 80px;
   }
   .e-marketDetails-card .e-primary-blue-btn, .e-marketDetails-card .e-primary-otln-btn{
      padding: 8px 0px;
   }
   /*fAQ tab*/
    .e-ac-open-card  .e-card-explore{
      font-size: 10px;
   }
   /*user profile purchase card*/
    .e-purchase-card .e-primary-blue-btn{
      padding: 11px 5px;
   }
   .e-blog-details-header{
    padding-bottom: 125px;
   }
   .e-contactus-img, .e-contact-card .e-form-rule{
      display: none;
   }


}